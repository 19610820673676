import React from 'react';
import * as Redux from 'react-redux';
import { Columns, Row } from '../../../styled/flex.styled';
import { Pizza } from './pizza.component';
import { IconPrimary } from '../../../styled/general.styled';
import { AddItemButton } from '../../../styled/forms.styled';
import { Paragraph, Title } from '../../../styled/text.styled';
import { useTranslate } from '../../../hooks/translate.hook';
import { isPromoPizza3 } from '../../../helpers/general.helper';
import Extras from './Extras';

interface iPromo {
   data: any;
   setError: CallableFunction;
   cartOperation: any;
   cartHolder: any;
   step: [any, CallableFunction];
   opt: any;
   erase: CallableFunction;
   setActive: any;
}

interface iPizzaDesc {
   data: any;
   setActive: CallableFunction;
   cartOperation: any;
}

export const PizzaDesc: React.FunctionComponent<iPizzaDesc> = (props) => {
   const t = useTranslate();

   return (
      <Row as="li" className="flex-12" onClick={() => props.setActive(props.data)}>

         <Columns
            notAlignCenter={true}
            justifyContent="flex-start"
            className="pb-1 flex-7"
            onClick={() => function () { console.log("oi") }}
         >
            <Title
               as="h6"
               size={1}
               style={{ margin: 0 }}
            >
               {props.data.tamanho}
            </Title>

            <Paragraph>
               {props.data.descricao || 'Sem descricao'}
            </Paragraph>
         </Columns>
         <Columns
            notAlignCenter={true}
            justifyContent="flex-end"
            className="pb-1 flex-5"
         >
            <Row justifyContent="flex-end">
               <Paragraph>
                  {t.see} pizza
                    </Paragraph>
               <AddItemButton>
                  {
                     props.cartOperation.existsPromo(props.data) ?
                        <IconPrimary>
                           check_box
                                </IconPrimary>
                        :
                        <IconPrimary>
                           check_box_outline_blank
                                </IconPrimary>

                  }
               </AddItemButton>
            </Row>
         </Columns>
      </Row >
   );
}

export const PromoPizza: React.FunctionComponent<iPromo> = (props) => {

   const dispatch = Redux.useDispatch();

   const t = useTranslate();
   const [active, setActive]: any = React.useState({});
   const [index, setIndex] = React.useState(0);

   React.useEffect(() => {

      if (Object.keys(active).length === 0) {
         props.erase(false);
         props.step[1]({
            ...props.step[0],
            has: 1,
            page: 1,
            names: [t.add_carrinho],
            action: function (active: any) {
               props.cartOperation.addProductToCart(active, dispatch, setActive)
            }
         });
      } else {
         props.erase(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [active]);

   const data = props.data.conjuntos;

   return (
      <>
         {
            Object.keys(active).length === 0 ?
               props.data.conjuntos ?
                  data.map((promo: any, index: number) => (
                     <React.Fragment key={index}>
                        {
                           promo.tp_conjunto === 'pizza' ?
                              <>
                                 <li>
                                    <Title as="h6" className="no-pad pt-2 pb-2">
                                       {promo.tp_conjunto}
                                    </Title>
                                 </li>
                                 <li className="flex-12">
                                    <Columns
                                       as="ul"
                                       justifyContent="flex-start"
                                       notAlignCenter={true}
                                       className="flex-12"
                                    >
                                       {
                                          <Columns
                                             as="ul"
                                             justifyContent="flex-start"
                                             notAlignCenter={true}
                                             className="flex-12 border-left pl-3"
                                          >
                                             {
                                                promo.produto.map((prod: any, id: number) => (
                                                   promo.tp_conjunto === 'pizza' ?
                                                      <PizzaDesc
                                                         key={promo.tp_conjunto + id}
                                                         data={{ ...prod, indexCat: index }}
                                                         cartOperation={props.cartOperation}
                                                         setActive={(val: any) => {
                                                            setActive(val);
                                                            setIndex(index);
                                                         }}
                                                      />
                                                      : null
                                                ))
                                             }
                                          </Columns>
                                       }
                                    </Columns>
                                 </li>
                              </>
                              : null
                        }
                     </React.Fragment>
                  ))
                  : null
               :
               <Pizza
                  data={{
                     ...active,
                     maxKind: active.qtde_sabores,
                     parent: { nome: index },
                     opt: {
                        cobrar_borda: active.cobrar_borda,
                        cobrar_vl_pizza: active.cobrar_vl_pizza,
                        mostrar_borda: active.mostrar_borda,
                     }
                  }}
                  steps={props.step}
                  promoAction={() => setActive({})}
                  cartHolder={props.cartOperation.getPizza}
                  cartOperation={props.cartOperation}
                  title={t.choose + " pizza"}
               />
         }
         {
            isPromoPizza3(props.data) ?
               <Extras
                  cart={props.cartOperation.getPizza}
                  cartOperation={props.cartOperation}
                  loop={{ desc: [props.data], indexCat: index }}
                  loopable="desc"
                  type="promo_pizza_3"
                  className="border-left pl-3"
               />
               : null

         }
      </>
   );
}
