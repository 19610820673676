import { Dispatch } from 'react';
import { app } from '../app_env';
import { iApiItens } from '../helpers/types/iSetApiData';
import { sendToTable } from './sendToTable';
import { validate } from './validate';


const send = (
    waitTime: Date,
    setWaitTime: CallableFunction,
    customer: string,
    table: string,
    props: any,
    data: any,
    dispatch: Dispatch<any>
) => {
    const rightNow = new Date();
    const oneSecondAgo = rightNow.getTime() - waitTime.getTime();

    if (oneSecondAgo > 1) {
        if (!!table) {
            const json = validate(customer.length > 0 ? customer : '11111111111', table, props.cart, data);

            sendToTable(json, props.closeModal, props.setSuccess, dispatch);
            setWaitTime(new Date());
        } else {

        }
    }
}
const sendMiddleware = (
    setCamera: CallableFunction,
    waitTime: Date,
    setWaitTime: CallableFunction,
    customer: string,
    table: string,
    props: any,
    data: iApiItens,
    dispatch: Dispatch<any>,
    setSuccess: CallableFunction,
) => {
    if (app.type === "garcon") {
        send(waitTime, setWaitTime, customer, table, props, data, dispatch);
    }
    else if (data.mesa_compl === 0 && app.type === "mesa") {
        send(waitTime, setWaitTime, customer, table, props, data, dispatch);
    }
    else if (app.type === "mesa" && customer.length) {
        setCamera([true, table, customer, props, data]);
    }
    else if (!customer.length) {
        setSuccess({
            active: true,
            success: false,
            message: 'Por favor, nos informe seu telefone',
        });
    }
}

export default sendMiddleware;