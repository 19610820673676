import React from 'react';
import * as Redux from 'react-redux';
import { asyncSetData } from '../redux/actions/action-functions.actions';
import { reduxState } from '../redux/reducers';
import { useParams } from '@reach/router';

export const useInit = () => {

    const dispatch = Redux.useDispatch();
    const params = useParams();
    const { data } = Redux.useSelector((state: reduxState) => state.setApiData)

    React.useEffect(() => {

        if (typeof data.payload !== 'undefined')
            dispatch(asyncSetData(`https://json_armazem.alphi.com.br/${params.id}/empresa.json`));
        // eslint-disable-next-line
    }, [dispatch]);

    React.useEffect(() => {
        
        const title = document.querySelector('title');

        if (data.nome && title !== data.nome){
            
            // title.text = data.nome;
            // document.querySelector('link[rel=icon]').setAttribute('href', data.logo);
        }

    }, [data]);

    return data;
}
