import styled from 'styled-components';
import { ITEM_SIZE } from '../constants/styles';

interface iRow {
    notAlignCenter?: boolean;
    justifyContent?: string;
    grid?: boolean;
}

export const Row = styled.div<iRow>`
    display: ${(props) => props.grid ? 'grid' : 'flex'};
    ${(props) => props.grid ? `
        grid-template-columns: repeat(3, ${ITEM_SIZE.threeColumns}%);
        gap: 15px;
        @media screen and (min-width: 770px) {
            &>li, &>a {
                width: 100%;
            }
        }
    ` : ''}
    flex-wrap: wrap;
    flex-direction: row;
    align-items: ${(props) => props.notAlignCenter ? 'flex-start' : 'center'};
    justify-content: ${(props) => props.justifyContent || 'flex-start'};
    width: 100%;
    list-style: none;
    margin-left: -5px;
    margin-right: -5px;
    padding: 0;
`;

export const RowCategories = styled(Row)`
    background-color: white;
    border-radius: 10px;
    margin-top: -6px;
    overflow-x: scroll;
    overflow-y: visible;
    flex-wrap: nowrap;
    padding-top: 1rem;
    padding-bottom: .9rem;
    padding-left: 10px;
    padding-right: 5px;
    margin-right: 2rem;
    margin-left: 0;
    width: 100vw;
`;

export const Columns = styled(Row)`
    flex-direction: column;
    margin: 0;
`;

