import { takeLatest, all } from 'redux-saga/effects';
import { ASYNC_CALL } from '../actions/actionTypes';
import { asyncCall } from './sagas';

/**
 *  This function will make all the api calls,
 *  I have no idea how to describe very well, but here
 *  is where all the api calls start, first we will listen
 *  for the ASYNC_CALL type and then perform an action, the
 *  action is basicly call the api defined in the parameter
 *  of the action and set it to the typeTo reducer type 
 */

function* watchAsyncCall() {
    yield takeLatest(ASYNC_CALL, asyncCall)
}

export default function* root() {
    yield all([
        watchAsyncCall(),
    ])
} 