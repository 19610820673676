import React from 'react'
import { CHECK_TYPES } from '../../constants/geral'
import { hasOneOf } from '../../helpers/general.helper'
import { Row } from '../../styled/flex.styled'
import { AddItemButton } from '../../styled/forms.styled'
import { BorderRounded, IconPrimary } from '../../styled/general.styled'
import { TitleColor } from '../../styled/text.styled'

interface iCartButtons {
    cartOperation: any;
    item: any;
    type: string;
}

export const CartButtons: React.FunctionComponent<iCartButtons> = (props) => {

    const howMuch = props.cartOperation.howMuchExists;
    const update = props.cartOperation.update;

    if (!hasOneOf(props.type, ...CHECK_TYPES)) {
        return (
            <Row justifyContent="flex-end">
                {
                    howMuch(props.item, props.type) ?
                        <AddItemButton onClick={() => update(props.item, '-', props.type)}>
                            <IconPrimary>
                                remove
                            </IconPrimary>
                        </AddItemButton>
                        : null
                }
                <BorderRounded color="mainColor">
                    <TitleColor color="mainColor" size={1}>
                        {howMuch(props.item, props.type)}
                    </TitleColor>
                </BorderRounded>


                <AddItemButton onClick={() => update(props.item, '+', props.type)}>
                    <IconPrimary>
                        add
                </IconPrimary>
                </AddItemButton>
            </Row>
        );
    } else {
        return (
            <Row
                justifyContent="flex-end"
                onClick={() => update(props.item, howMuch(props.item, props.type) ? '-' : '+', props.type)}
            >
                {
                    howMuch(props.item, props.type) ?
                        <AddItemButton>
                            <IconPrimary>
                                check_box
                            </IconPrimary>
                        </AddItemButton>
                        :

                        <AddItemButton>
                            <IconPrimary>
                                check_box_outline_blank
                            </IconPrimary>
                        </AddItemButton>
                }
            </Row>
        );
    }
}
