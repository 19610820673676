// @ts-ignore
import camera from '../img/camera.webp';
import { iCartInitial } from '@cart/cart-initial';

export const sizeName = (name: string) => {
    if (name.length > 30) return '12';
    else if (name.length > 25) return '9';
    else if (name.length > 20) return '7';
    else if (name.length > 15) return '6';
    else if (name.length > 10) return '5';
    else if (name.length > 7) return '4';
    else return '3';
}

export const handle404 = (event: any) => {
    event.target.src = camera;
}

export const isDescritiva = (data: any) => (
    data.tipo === 1
    && typeof data.desconto !== 'undefined'
    && !data.produtos
    && !data.itens
    && !data.pizzas
)
export const isPromoPizza3 = (data: any) => (
    data.tipo === 3
    && !data.produtos
    && !data.itens
    && !data.pizzas
)

export const hasOneOf = (find: string, ...stuff: any) => stuff.indexOf(find) !== -1;

export const isOneOf = (check: any, ...oneOf: any) => {
    for (let i = 0; i < oneOf.length; i++) {
        if (check === oneOf[i]) {
            return true;
        }
    }
    return false;
}


export const countCart = (cart: iCartInitial[]) =>
    cart.reduce((prev, cur) => (cur.quantity || 1) + prev, 0)