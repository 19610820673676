import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../hooks/translate.hook';
import { reduxState } from '../../redux/reducers';
import { Columns, Row } from '../../styled/flex.styled';
import { TitleColor } from '../../styled/text.styled';

const PaymentComponent: React.FunctionComponent<any> = () => {
    const t = useTranslate();
    const data = useSelector((state: reduxState) => state.setApiData.data);

    return (
        <>
            <Row justifyContent="space-bewtween" className="pb-2" style={{ marginLeft: -15 }}>
                <TitleColor color="light">{t.payment_methods}</TitleColor>
                {
                    data.pagamento?.tipos?.map((payment: any) => (
                        <Columns className="flex-6" notAlignCenter={true}>
                            <p>
                                {payment.nome}
                            </p>
                        </Columns>
                    ))
                }
                <Columns className="flex-12" notAlignCenter={true}>
                    <TitleColor color="light">{t.finish}</TitleColor>

                </Columns>
            </Row>
        </>
    )
}

export default PaymentComponent;