import axios from "axios"
import { Dispatch } from "react";
import { app } from '../app_env';
import { clearCart } from "../redux/actions/action-functions.actions";
import { iSendJson } from './validate';

type func = () => void;

export const sendToTable = (
    mesaObject: iSendJson,
    close: func,
    setSuccess: CallableFunction,
    dispatch: Dispatch<any>
) => {
    if (process.env.NODE_ENV === 'production') {

        axios.post(app.post_mesa, mesaObject)
            .then(() => {

                close();
                dispatch(clearCart());
                setSuccess({
                    active: true,
                    success: true,
                    message: 'Este pedido foi enviado para a cozinha',
                })
            })
            .catch((err) => {
                console.log(err);

            });
    }
    else {
        console.log(mesaObject)
        close();
        // dispatch(clearCart());
        // setSuccess({
        //     active: true,
        //     success: true,
        //     message: 'Este pedido foi enviado para a cozinha',
        // })
    }
}
