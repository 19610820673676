import styled from 'styled-components';

export const HeaderContainer = styled.header.attrs({ className: 'flex' })`
  width: 100%;
  background-color: ${ (props) => props.theme.mainColor };
  padding: 1.5rem;
  padding-top: 1rem;
`;

interface iHeaderCategory {
   align: string | undefined;
}

export const HeaderCategory = styled.header.attrs({ className: 'flex' })<iHeaderCategory>`
  padding: .5rem 1.5rem;
  background-color: ${ (props) => props.theme.light };
  align-items: center;
  justify-content: ${ (props) => props.align || 'flex-start' };
`;

export const HeaderCategoryImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: .8rem;
`;

interface iSuccessFailure {
   active: boolean;
   success: boolean;
}

export const SuccessFailure = styled.div<iSuccessFailure>`
   width: 90vw;
   position: fixed;
   top: 5%;
   left: 5vw;
   border-radius: 7px;
   min-height: 50px;
   background-color: ${ (props) => props.success ? props.success === true ? 'rgba(0, 200, 81, .85)' : 'rgba(255, 238, 51, .85)' : 'rgba(255, 68, 68, .85)' };
   border: 2px solid ${ (props) => props.success ? props.success === true ? '#09a949' : '#b28704': '#ff4444' };
   transition: ease-in-out .7s;
   margin-top: ${(props) => props.active ? '0' : '-50vw'};
   padding: .5rem 1.5rem;
`;