import { Cart } from './cart.cart';
import { iCartInitial } from './cart-initial';

export class CartPizza extends Cart {

    update = (newItem: any, operation: string, type: string = 'extra') => {

        let result: Array<any> = [];
        let field = "";

        switch (type) {
            case 'pizza':
                result = this._updatePizza(newItem, operation);
                field = "sabor";
                break;
            case 'sabores':
                result = this._updatePizza(newItem, operation);
                field = "sabor";
                break;
            case 'edge':
                result = this._updateEdge(newItem, operation);
                field = "borda";
                break;
            case 'dough':
                result = this._updateDough(newItem, operation);
                field = "massa";
                break;
            case 'extra':
                result = this._updateExtra(newItem, operation);
                field = "adicionais";
                break;
            case 'side':
                result = this._updateSide(newItem, operation);
                field = "acompanhamentos";
                break;
            default:
                break;
        }

        const newCart: iCartInitial = {
            ...this._cart,
            itens: {
                ...this._cart.itens,
                [field]: result
            }
        }
        this._cart = newCart;
        this._addToCart(newCart);
    }

    _updatePizza = (newItem: any, operation: string): Array<any> => {

        let kinds = this._cart.itens?.sabor ?? [];

        const exists = this.howMuchExists(newItem, 'pizza');

        if (kinds.length < newItem.max.max && exists === 0)
            kinds.push(newItem);
        else if (exists === 1) {
            const index = kinds.findIndex((item) => (
                item.id === newItem.id && item.nome_sabor === newItem.nome_sabor
            ));

            kinds.splice(index, 1)
        } else if (newItem.max.max === 1 || !newItem.max.max) {
            kinds = [newItem];
        }
        else {
            this._errors({ errors: [`LIMIT_OF_${ newItem.max.max }`] });
        }

        return kinds;
    }

    _updateEdge = (newItem: any, operation: string): Array<any> => {
        return [newItem];
    }

    _updateDough = (newItem: any, operation: string): Array<any> => {
        return [];
    }
}