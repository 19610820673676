import styled from 'styled-components';
import * as COLUMNS from '../constants/styles';


interface iIcon {
   className?: string;
   pad?: string;
   color?: string;
}

export const Icon = styled.span.attrs({
   className: `material-icons ${(props: iIcon) => props.className || ''}`
}) <iIcon>`
    padding: ${(props) => props.pad || 0};
    color: ${(props) => props.color || '#000'};
`;

export const IconPrimary = styled(Icon)`    
    color: ${(props) => props.theme.mainColor};
`;

export const ProductItem = styled.li.attrs({
   className: 'product-item'
})`
    background-color: #fff;
    height: 100%;
    width: ${COLUMNS.ITEM_SIZE.threeColumns}vw;
    flex: 0 0 ${COLUMNS.ITEM_SIZE.threeColumns}vw;
    @media screen and (max-width: 600px) {
       margin-left: 1vw;
       &:first-child {
          margin-left: 3vw;
         }
         &:nth-child(3n+4) {
            margin-left: 3vw;
         }
      }   
    margin-right: 1.5vw;
    @media screen and (min-width: 800px) {
        width: ${COLUMNS.ITEM_SIZE.threeColumns}%;
        flex: 0 0 ${COLUMNS.ITEM_SIZE.threeColumns}%;
    }
`;

export const ItemImage = styled.img`
    width: ${COLUMNS.PROD_IMAGE_WIDTH_PERCENT}%;
    /* height: ${COLUMNS.PROD_IMAGE_HEIGHT_PERCENT}%; */
    opacity: .9;
    border-radius: 5px;
`;

interface iBorderRounded {
   color: string;
}

export const BorderRounded = styled.div.attrs({
   className: 'flex _center'
}) <iBorderRounded>`
    border: 1px solid ${(props) => props.theme[props.color]};
    width: 25px;
    height: 25px;
    margin: 0;
    border-radius: 50%;
    text-align: center;
    padding-bottom: 1px;
`;

interface iCollapse {
   collapsed?: boolean;
}

export const CollapseHeader = styled.div<iCollapse>`
    box-shadow: ${() => 'none'};
    margin: 2px;
`;

export const Collapse = styled.div<iCollapse>`
    max-height: ${() => '100%'};
    visibility: ${() => 'visible'};
    transition: ease .5s;
    overflow: hidden;
`;

export const Border = styled.div`
    box-shadow: 3px 3px 3px rgba(0,0,0,.3);
`;

export const CartCounting = styled.div`
   width: 0;
   position: relative;
   left: 60%;
   top: -25px;
   &>div {
      border: 1px solid ${(props) => props.theme.mainColor};
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      &>span:first-child {
         position: absolute;
         margin-left: 15px;
         margin-top: -22px;
         font-weight: bold;
         color: ${(props) => props.theme.danger};
      }
      &>span:last-child {
         font-size: 1.3rem;
      }
   }
`;

interface iAbsoluteButtonsAction {
   parentProperties: any;
   showButtons: boolean;
}

export const AbsoluteButtonsAction = styled.aside.attrs({
   className: 'flex _center'
}) <iAbsoluteButtonsAction>`
   position: absolute;
   width: ${(props) => props.parentProperties?.offsetWidth ?? 0}px;
   height: ${(props) => props.parentProperties?.offsetHeight ?? 0}px;
   background-color: ${(props) => props.theme.mainColor};
   z-index: ${(props) => props.showButtons ? 2 : -1};
   opacity:  ${(props) => props.showButtons ? .75 : 0};
   border-radius: 5px;
   left: ${(props) => props.parentProperties?.offsetLeft ?? 0}px;
   top: ${(props) => props.parentProperties?.offsetTop ?? 0}px;
`;

export const Table = styled.table`
   color: #000; 
   font-size: 1rem; 
   border-collapse: collapse; 
`;

export const TableRow = styled.tr.attrs({ className: 'border-bottom' })`
   color: black;
`;