import { createGlobalStyle } from "styled-components";
import 'styled-components';

type theme = {
    mainColor: string;
    light: string;
    dark: string;
    transparentDark: string;
    danger: string;
    success: string;
    warnning: string;
    [key: string]: any;
}

export const danger = "#ff4444";

declare module 'styled-components' {
    export interface DefaultTheme extends theme { }
}

export const mainTheme = (data: any): theme => ({
    mainColor: data.cor_padrao,
    light: '#fefefe',
    dark: '#010101',
    transparentDark: 'rgba(10, 10, 10, .3)',
    warnning: "#b28704",
    danger,
    success: "#00C851",
});

export const GlobalStyles = createGlobalStyle`
    :root {
        --primary: ${(props) => props.theme.mainColor};
    }
`;