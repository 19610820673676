export const portuguese = {
    categoria: "categoria",
    sabor: "sabor",
    sabores: "sabores",
    bordas: "bordas",
    massas: "massas",
    extras: "adicionais",
    add_carrinho: "Adicionar ao carrinho",
    side_dishes: "acompanhamentos",
    close: "Fechar",
    goToBordas: "Ir para as bordas",
    see: "Ver",
    choose: "Escolher",
    back: "Voltar",
    goToExtras: "Ir aos adicionais",
    see_list: "Ver lista",
    cart: 'Carrinho',
    sendToKitchen: "Enviar pedido",
    finish: "Finalizar",
    tableNumber: "Mesa nº",
    numberOfTable: "Número da mesa",
    number: "Número de telefone",
    name: "Nome",
    quantity: "Qtd",
    value: "Valor",
    search: "Pesqusiar",
    remove: "Remover",
    payment_methods: "Metodos de pagamento",
    observation: "Alguma observação",

    itens: function (qtd: number = 1) {

        return qtd > 1 ? "Itens" : "Item";
    },

    limit_of: "quantidade máxima de",
    error: function (error: string) {

        const err = error?.replace(/_\d/g, "")?.toLowerCase() ?? "Houve um problema";
        const number = error.replace(/\D/g, "");

        const that: any = this;

        if (that[err])
            return that[err] + " " + number;
        return error;
    }
}