type appTypes = ("garcon" | "mesa" | "pedido");

const isBrowser = () => typeof window !== "undefined"

const appType: appTypes = isBrowser() && window?.location?.host?.match(/(atendimento)|(localhost)/) ? 'garcon' : 'mesa';

export const app = {
    type: appType,
    get_mesa: "https://api-armazem.alphi.com.br/pedidos/mesa/{canal}/{numero}/{mesa}",
    post_mesa: "https://orders-armazem.alphi.com.br/api/v1/setpedidomesa",
    user_api: "https://api-armazem.alphi.com.br/cliente/dados",
}