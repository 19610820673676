import { app } from "../app_env";
import { CalculateCart } from "../cart/calculate.cart";
import { iCartInitial } from "../cart/cart-initial";
import { configureCart, iProducts } from "./confiure-cart";

interface iCustomer {
    nome?: string;
    telefone: string;
    coupon?: Array<any>;
    entrega?: Array<any>;
    metodo_pagamento?: string;
    acrescimo?: number;
    desconto?: number;
    metodo_entrega?: string;
    presente?: Array<any>;
    agendar?: Array<any>;
    troco?: number;
    observacao_geral?: string;
    // iugu?: string;
    cartao?: Array<any>;
    email?: string;
    mesa: string;
}

export interface iSendJson {
    produtos: Array<iCartInitial> | Array<iProducts>;
    cliente: iCustomer;
    canal: string;
    valor_produtos: number;
    pago: boolean;
    completo: boolean;
    tipo: ("mesa" | "link" | "garcon");
    tipo_pedido: ("mesa" | "link" | "garcon");
    valor_total: number;
}

export const validate = (
    customer: string,
    table: string,
    cart: Array<iCartInitial>,
    store: any,
    imageName: string = "",
): iSendJson => {

    let cliente: iCustomer = {
        telefone: customer?.trim(),
        nome: customer?.trim(),
        mesa: table?.trim(),
    }

    let cartFormatted = configureCart(cart, store);

    const val = new CalculateCart(cart, { howToPayPizza: store.como_cobrar_pizza });

    let send: iSendJson = {
        produtos: cartFormatted,
        cliente: cliente,
        valor_produtos: val.getAll(),
        pago: false,
        canal: store.canal,
        completo: false,
        tipo_pedido: app.type,
        tipo: "mesa",
        valor_total: val.getAll(),
    };

    if (imageName) {
        Object.assign(send, { imagem: imageName })
    }

    return send;
}