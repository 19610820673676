export const normalize = (word: string, lower: boolean = false): string => {
    let normalized = word.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    if (lower) {
        normalized = normalized.toLowerCase();
    }
    return normalized;
}

export const replacer = (word: string, cutSpaces: boolean = false): string => {
    let newWord = normalize(word, true);

    if (cutSpaces) {
        newWord = newWord.replace(/'|"|\\|\/\s|\.|\$|\*|\(|\)/g, "_");
        newWord = newWord.replace(/\s/g, "-");
    }
    return newWord;
}

export const readMore = (word: string, full: boolean): [string, boolean] => {
    if (full) {
        return [word, false];
    } else if (word && word.length > 30) {
        return [word?.substring(0, 30) + '...', true];
    } else {
        return [word, false];
    }
}