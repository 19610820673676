import React from 'react';
import * as Redux from 'react-redux';
import { reduxState } from '../../redux/reducers';
import { Row } from '../../styled/flex.styled';
import { ListItem } from '../general/ListItem.component';
import { iSwitcher } from '../category/switcherList.component';

interface iItemFromURL extends iSwitcher {
   id: string | number;
   type: string;
}

const ItemFromURL: React.FunctionComponent<iItemFromURL> = (props) => {

   const { cat, cart } = Redux.useSelector((state: reduxState) => {
      const cats = state.categoriesData.categories;

      return { cat: cats[props.type], cart: state.cart };
   });

   return (
      <Row as="ul" grid justifyContent="flex-start">
         {
            cat?.categorias ?
               cat.categorias.map((categories: any) => (
                  categories.produtos ?
                     categories.produtos.map((item: any) => (
                        <ListItem
                           key={item.id}
                           data={{
                              ...item,
                              nome: item.nome || item.tamanho,
                              weight: 700,
                              size: .9,
                              color: '#000',
                              type: props.type
                           }}
                           onClick={() => props.active({ ...item, nome: item.nome || item.tamanho, type: props.type })}
                           cart={cart.filter((it) => it.nome === (item.nome || item.tamanho) && it.id === item.id)}
                        />
                     ))
                     : null
               ))
               : null
         }
      </Row>
   );
}

export default ItemFromURL;