import React from 'react';
import { Row } from '../../styled/flex.styled';
import { Paragraph, Title } from '../../styled/text.styled';


interface iValueFromTo {
   tipo_acompanhamento_nome: string | number | null;
   qtde_escolha_obrigatoria: string | number | null;
   qtde_max_escolha: number | string | null;
}

export const ValueFromTo: React.FunctionComponent<iValueFromTo> = (props) => {
   return (
      <Row>
         {
            props.tipo_acompanhamento_nome ?
               <Title as="h6" className="pr-1">
                  {props.tipo_acompanhamento_nome}
               </Title>
               : null
         }

         <Paragraph>
            {
               props.qtde_escolha_obrigatoria ?
                  `Min: ${ props.qtde_escolha_obrigatoria }`
                  : null
            }
         </Paragraph>
         <Paragraph className="pl-1">
            {
               props.qtde_max_escolha ?
                  `Até: ${ props.qtde_max_escolha }`
                  : null
            }
         </Paragraph>
      </Row>
   );
}

