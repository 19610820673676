import React from 'react';
import * as Redux from 'react-redux';
import { Link, navigate, useParams } from '@reach/router';
import { Icon } from '../styled/general.styled';
import { Row } from '../styled/flex.styled';
import { HeaderContainer } from '../styled/header.styled'
import { Title } from '../styled/text.styled';
// import { useTranslate } from '../hooks/translate.hook';
import { normalize } from '../helpers/replacer';
import { Helmet } from 'react-helmet';
import { reduxState } from '../redux/reducers';
import CategoriesList from './header/CategoriesList';
// @ts-ignore
import homeIcon from '../../images/home.webp';
// @ts-ignore
import lupaIcon from '../../images/lupa.png';

interface iSearch {
   button: boolean;
   input: boolean;
}

const MainHeader: React.FunctionComponent<any> = () => {

   const {
      nome,
      departamento,
      logo,
   } = Redux.useSelector((state: reduxState) => state.setApiData.data);

   const params = useParams();
   let inputRef = React.useRef(null);

   const [showSearch, setShowSearch] = React.useState<iSearch>({ button: false, input: false });
   const [search, setSearch] = React.useState('');

   const makeSearch = (form: any) => {
      form.preventDefault();
      navigate(`/${params.id}/pesquisa/${normalize(search, true).replace(' ', '---')}`)
   }

   const isHome = params.cat !== undefined;

   const handleShowSearch = (show: boolean) => {
      setShowSearch({ ...showSearch, button: show });
      if (inputRef && inputRef.current && show) {
         inputRef.current.focus()
      } else if (inputRef && inputRef.current && !show) {
         inputRef.current.blur()
      }
   }
   return (
      <>
         <HeaderContainer>
            <Helmet>
               <title>{nome || "Alphi"}</title>
               <link rel="icon" href={logo} />
               <meta property="og:title" content={nome || "Pedido online"} />
               <meta property="og:description" content={"Fazer pedido online"} />
               <meta property="og:image" content={logo} />
            </Helmet>

            <Row className="flex-12">
               {
                  isHome ?
                     <Link to={`/${params.id}`} className="flex-2">
                        {/* @ts-ignore */}
                        <Icon color="#fff" pad="5px 0 0 0" className="has-text-shadow-light">
                           arrow_back
                        </Icon>
                     </Link>
                     :
                     <img
                        className="has-text-shadow-light"
                        style={{
                           color: '#fff',
                           padding: '5px 0 0 0',
                           marginRight: 10,
                           width: 35,
                        }}
                        src={homeIcon}
                     />
                  // @ts-ignore 
                  // <Icon color="#fff" className="has-text-shadow-light flex-2">
                  //    home
                  // </Icon>
               }

               <Title as="h2" size={1.1} color="#fff" className="flex-8 has-text-shadow-light">
                  {nome}
               </Title>

               <Row as="ul" className="flex-1 is-white has-text-shadow-light">
                  <li>
                     <button
                        onFocus={() => handleShowSearch(!showSearch.button)}
                        onBlur={() => handleShowSearch(false)}
                        type="button"
                        className="is-invisible"
                     >
                        <img
                           className="has-text-shadow-light"
                           style={{
                              color: '#fff',
                              padding: '5px 0 0 0',
                              width: 32,
                           }}
                           src={lupaIcon}
                        />
                     </button>
                     <form
                        onFocus={() => handleShowSearch(true)}
                        onBlur={() => handleShowSearch(false)}
                        style={{
                           position: 'fixed',
                           marginLeft: '-100%',
                           right: showSearch.button || showSearch.input ? '-5%' : '-100%',
                           width: '100%',
                           transition: 'ease-in-out .5s',
                        }}
                        onSubmit={(form) => makeSearch(form)}
                     >
                        <input
                           ref={inputRef}
                           onChange={(val) => setSearch(val.target.value)}
                           style={{
                              width: '90%',
                              padding: '5px 15px',
                              borderRadius: 5,
                           }}
                           type="text"
                        />
                     </form>
                  </li>
               </Row>
            </Row>
         </HeaderContainer >

         <CategoriesList departamento={departamento} />
      </>
   );
}

export default MainHeader;