import { iCartInitial } from "../../cart/cart-initial";
import { ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART, ADD_ALL, REMOVE_BY_INDEX } from "../actions/actionTypes";
import lodash from 'lodash';

interface iCartReducer {
    type: string;
    payload: iCartInitial | any;
}

const initialState: Array<iCartInitial> = [];

const itemEqual = (stateItem: iCartInitial, newItem: iCartInitial) => stateItem.id === newItem.id && stateItem.nome === newItem.nome;

export const cart = (state = initialState, action: iCartReducer) => {
    switch (action.type) {
        case ADD_TO_CART:

            let newItens = Array.isArray(action.payload) ? action.payload : [action.payload];

            newItens.forEach((newItem) => {
                if (newItem.quantity === 0) {
                    newItem.quantity = 1;
                }

                const alreadyExists = state.filter((item) => itemEqual(item, newItem));

                if (alreadyExists.length > 0) {
                    const compareState = { ...alreadyExists[0] };
                    const compareNewItem = { ...newItem };
                    compareState.quantity = 1
                    compareNewItem.quantity = 1

                    if (lodash.isEqual(compareState, compareNewItem)) {

                        const index = state.findIndex((item) => itemEqual(item, newItem));
                        newItem.quantity += alreadyExists[0].quantity;

                        state.splice(index, 1, newItem);
                    } else {
                        state.push(newItem);
                    }

                } else {
                    state.push(newItem);
                }
            })

            return [...state];
        case ADD_ALL:
            return action.payload;

        case REMOVE_FROM_CART:

            const removeItem = action.payload;

            const itemInCart = state.filter((item) => item.nome === removeItem.nome && item.parent_name === removeItem.parent_name);

            if (itemInCart.length > 0) {
                const first = itemInCart[0];

                const index = state.findIndex((item) => item.nome === first.nome && item.parent_name === first.parent_name);

                if (first.quantity > 1) {

                    state.splice(index, 1, { ...first, quantity: first.quantity - 1 });

                } else if (first.quantity === 1) {
                    state.splice(index, 1);
                }
            }

            return [...state];

        case REMOVE_BY_INDEX:
            const index: number = action.payload;
            state.splice(index, 1);

            return [...state];

        case CLEAR_CART:
            return [];
        default:
            return state;
    }
}