import styled from "styled-components";

export const Button = styled.button`
    background: transparent;
    border: none;
`;

export const AddItemButton = styled(Button)`
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: ${(props) => props.theme.mainColor};
`;

export const ButtonPadding = styled(Button)`
    padding: .7rem 1.2rem;
    background-color: ${(props) => props.theme[props.color ?? 'danger']};
    border-radius: 5px;
    color: ${(props) => props.color !== 'light' ? '#fff' : '#000'};
    font-weight: bold;
`;

export const InputText = styled.input`
    border: none;
    padding: .7rem 1.3rem;
    background-color: rgb(255,255,255);
    color: rgba(0,0,0,.4);
    border-radius: 5px;
    width: 98%;
`;

interface iButtonAddOrRemoveSingle {
    success: boolean;
}

export const ButtonAddOrRemoveSingle = styled.button<iButtonAddOrRemoveSingle>`
   border: 3.5px solid ${(props) => props.success ? props.theme.success : props.theme.danger};
   background-color: ${(props) => props.success ? 'rgb(55, 255, 136)' : 'rgb(255, 98, 98)'};
   border-radius: 10px;
   width: 95%;
   height: 48%;
   opacity: 1;
   outline: none;
`;

export const FloatButton = styled.button`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: fixed;
    bottom: 5%;
    right: 7%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .3);
    border: none;
    background-color: ${(props) => props.theme.mainColor};
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
`;