import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { app } from '../../app_env';
import { iCartInitial } from '../../cart/cart-initial';
import sendMiddleware from '../../fetchTable/sendMiddleware';

import { useTranslate } from '../../hooks/translate.hook';
import { reduxState } from '../../redux/reducers';
import { Columns, Row } from '../../styled/flex.styled';
import { ButtonPadding, InputText } from '../../styled/forms.styled';
import { Border } from '../../styled/general.styled';
import { TitleColor } from '../../styled/text.styled';


interface iSendComponent {
    cart: Array<iCartInitial>;
    closeModal: () => void;
    setSuccess: CallableFunction;
    setFinish: CallableFunction;
    camera: any;
    setCamera: CallableFunction;
}

const SendComponent: React.FunctionComponent<iSendComponent> = (props) => {
    const dispatch = useDispatch();
    const t = useTranslate();
    const data = useSelector((state: reduxState) => state.setApiData?.data);
    const [table, setTable] = React.useState<string>("");
    const [customer, setCustomer] = React.useState<string>("");
    const [waitTime, setWaitTime] = React.useState<Date>(new Date());

    const send = () => {
        sendMiddleware(props.setCamera, waitTime, setWaitTime, customer, table, props, data, dispatch, props.setSuccess);
    }
    return (
        <>
            <Row justifyContent="space-bewtween" className="pb-2">
                <Columns className={app.type === 'mesa' && data.mesa_compl === 0 ? "flex-12" : "flex-6"} notAlignCenter={true}>
                    <TitleColor color="light">{t.tableNumber}</TitleColor>
                    <InputText
                        style={{ fontSize: '1rem' }}
                        value={table}
                        onChange={(input) => setTable(input.target.value)}
                        placeholder={t.numberOfTable + "..."}
                    />
                </Columns>
                {
                    app.type === 'mesa' && data.mesa_compl === 0 ?
                        null
                        :
                        <Columns className="flex-6" notAlignCenter={true}>
                            <TitleColor color="light">
                                {app.type === 'garcon' ? t.name : t.number}
                            </TitleColor>
                            <InputText
                                style={{ fontSize: '1rem' }}
                                value={customer}
                                onChange={(input) => setCustomer(input.target.value)}
                                placeholder={app.type === 'garcon' ? t.name + "..." : t.number + '...'}
                            />
                        </Columns>
                }
            </Row>

            <Row justifyContent="space-between">
                <Border className={"flex-12"}>
                    <ButtonPadding onClick={send} color="light" style={{ width: '100%' }}>
                        <TitleColor style={{ fontSize: '1rem', textAlign: 'center' }} color="mainColor">
                            {t.sendToKitchen}
                        </TitleColor>
                    </ButtonPadding>
                </Border>
            </Row>

        </>
    )
}

export default SendComponent;