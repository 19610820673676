import React from "react";
import { Router } from "@reach/router";
import { HomePage } from "../content/pages/home.page";
import { CategoryPage } from "../content/pages/category.page";
import NotFoundPage from "./404";
import { SearchPage } from "../content/pages/search.page";
import Store from "../content/redux";
import * as Redux from 'react-redux';
import '../content/app.sass';
// import 'material-icons';

const Main = (props: any) => (
  <Redux.Provider store={Store}>
    {props.children}
  </Redux.Provider>
);

const IndexPage = () => {
  return (
    <Router>
      <Main path=":id">
        <SearchPage path="/pesquisa/:search" />
        <CategoryPage path="/:dpt/:cat" />
        <HomePage path="/:dpt" />
        <HomePage path="/" />
      </Main>

      <NotFoundPage path="/" />
    </Router>
  )
}

export default IndexPage