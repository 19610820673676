import axios from 'axios'
import React from 'react'
import { useTranslate } from '../../hooks/translate.hook'
import { Columns, Row } from '../../styled/flex.styled'
import { ButtonPadding, FloatButton, InputText } from '../../styled/forms.styled'
import { Border, Icon } from '../../styled/general.styled'
import { AddToCartStyled, ModalOuter } from '../../styled/modal.styled'
import { TitleColor } from '../../styled/text.styled'
import { app } from '../../app_env';
import { useDispatch, useSelector } from 'react-redux'
import { reduxState } from '../../redux/reducers'
import { importCart } from '../../fetchTable/confiure-cart'
import { addAllToCart } from '../../redux/actions/action-functions.actions'

const FloatingButton: React.FunctionComponent = () => {
    const t = useTranslate();

    const dispatch = useDispatch();
    const { canal } = useSelector((state: reduxState) => state.setApiData.data)
    const [showCartModal, setShowCartModal] = React.useState<boolean>(false);
    const [table, setTable] = React.useState<string>("");
    const [customer, setCustomer] = React.useState<string>("");

    const searchUser = () => {
        axios.get(`${app.user_api}/${canal}/${customer || '11111111111'}`)
            .then((data) => {
                const products = data?.data?.data?.pedidos ?? {};

                const cart = importCart(products);
                dispatch(addAllToCart(cart));
                setShowCartModal(false);
            })
            .catch((err) => console.log(err));
    }

    return (
        app.type === "garcon" ?
            <aside>
                <FloatButton onClick={() => setShowCartModal(true)}>
                    <Icon color="#fffff">
                        person
                    </Icon>
                </FloatButton>

                <ModalOuter active={showCartModal} onClick={() => setShowCartModal(false)} z={2} />
                <AddToCartStyled
                    active={showCartModal}
                    style={{ padding: "0 1rem" }}
                    z={3}
                    top={70}
                    onClick={() => setShowCartModal(true)}
                >
                    <Row
                        justifyContent="space-bewtween"
                        className="pb-2 pt-2 pl-2"
                        style={{ marginLeft: -15 }}
                    >
                        <Columns className="flex-12" notAlignCenter={true}>
                            <TitleColor color="mainColor">
                                Pesquisar mesa
                            </TitleColor>
                        </Columns>
                        <Columns className="flex-6" notAlignCenter={true}>
                            <TitleColor w={700} color="mainColor" className="pt-1 pb-1">{t.tableNumber}</TitleColor>
                            <InputText
                                className="border-primary"
                                value={table}
                                onChange={(input) => setTable(input.target.value)}
                                placeholder={t.numberOfTable + "..."}
                            />
                        </Columns>
                        <Columns className="flex-6" notAlignCenter={true}>
                            <TitleColor w={700} color="mainColor" className="pt-1 pb-1">{t.name}</TitleColor>
                            <InputText
                                className="border-primary"
                                value={customer}
                                onChange={(input) => setCustomer(input.target.value)}
                                placeholder={t.name + "..."}
                            />
                        </Columns>
                        <Columns>
                            <Border style={{ marginTop: 5 }} onClick={searchUser}>
                                <ButtonPadding color="light">
                                    <TitleColor color="mainColor">
                                        Procurar
                                    </TitleColor>
                                </ButtonPadding>
                            </Border>
                        </Columns>
                    </Row>
                </AddToCartStyled>
            </aside>
            : null
    )
}

export default FloatingButton;