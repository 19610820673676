import { iCartInitial } from "./cart-initial"
import { CartParent } from './root.cart';

export class Cart extends CartParent {

    howMuchExists = (item: any, type: string = 'extra', length: boolean = false): number => {

        const exists = this._alreadyExists(item, type);

        if (exists.length > 0) {
            if (!length)
                return exists[0]?.quantity ?? 1;
            return exists[0]?.length ?? 1;
        }
        return 0;
    }


    update = (newItem: any, operation: string, type: string = 'extra') => {

        let newExtras: Array<any> = [];

        switch (type) {
            case 'extra':
                newExtras = this._updateExtra(newItem, operation);
                break;
            case 'side':
                newExtras = this._updateSide(newItem, operation);
                break;
            default:
                newExtras = this._updateExtra(newItem, operation);
                break;
        }

        const newCart: iCartInitial = {
            ...this._cart,
            itens: {
                acompanhamentos: type === 'extra' ? this._cart.itens.acompanhamentos : newExtras,
                adicionais: type === 'extra' ? newExtras : this._cart.itens.adicionais,
            }
        }

        this._addToCart(newCart);
    }

    addOne = () => {
        let qtd = this._cart.quantity > 0 ? this._cart.quantity : 1;

        this.qtdItemsCart = qtd + 1
    }
    removeOne = () => {
        let qtd = this._cart.quantity > 0 ? this._cart.quantity : 1;

        this.qtdItemsCart = qtd - 1
    }
}