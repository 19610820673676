import React from 'react';
import { useCurrency } from '../../hooks/currency.hook';
import { ValueStyled } from '../../styled/text.styled';

interface iValue {
   value: {
      value: number | null;
      hasDiscount?: boolean;
      valueNoDiscount?: number | null;
   };
   className?: string;
   valueClass?: string;
}

export const Value: React.FunctionComponent<iValue> = (props) => {

   const price = typeof props.value.value === 'number' ? props.value.value.toFixed(2) : "0,00";

   const currency = useCurrency();

   return (
      <div className={props.className || ""}>

         <ValueStyled
            discount={false}
            bold={props.value.hasDiscount}
            className={props.valueClass || ''}
            style={{ paddingBottom: 5 }}
         >
            {currency}{price}
         </ValueStyled>
         {
            props.value.hasDiscount && props.value.valueNoDiscount ?
               <ValueStyled bold={false} discount={true} lineThrough={true}>
                  {currency}{props.value.valueNoDiscount.toFixed(2)}
               </ValueStyled>
               : null
         }
      </div>
   );
}
