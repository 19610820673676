import React from "react";
import { iCartInitial } from "../../cart/cart-initial";
import { useTranslate } from "../../hooks/translate.hook";
import { ModalOuter, ModalStyled } from "../../styled/modal.styled";
import { Paragraph } from "../../styled/text.styled";
import { Headeritems } from "../header/headerItems.component";
// @ts-ignore
import cart from '../../img/cart.png';
import { Icon, Table, TableRow } from "../../styled/general.styled";
import { useDispatch, useSelector } from "react-redux";
import { removeItemByIndex } from "../../redux/actions/action-functions.actions";
import { Button } from "../../styled/forms.styled";
import { CalculateCart } from "../../cart/calculate.cart";
import { reduxState } from "../../redux/reducers";

interface iCartList {
    open: boolean;
    close: CallableFunction;
    cart: Array<iCartInitial>;
}

const CartList: React.FunctionComponent<iCartList> = (props) => {
    const t = useTranslate();
    const pizza = useSelector((state: reduxState) => state.setApiData?.data?.como_cobrar_pizza)

    const dispatch = useDispatch();
    const removeItem = (item: number) => {
        dispatch(removeItemByIndex(item));
    }

    return (
        <>
            <ModalStyled active={props.open}>
                <Headeritems
                    name={t.cart}
                    img={cart}
                    right={props.close}
                    space="space-between"
                />
                <Table>
                    <thead>
                        <tr>
                            <th style={{ paddingBottom: 15, minWidth: '50%', textAlign: 'left' }}>
                                {t.name}
                            </th>
                            <th style={{ paddingBottom: 15, minWidth: '10%' }} className="pr-2">
                                {t.quantity}
                            </th>
                            <th style={{ paddingBottom: 15, minWidth: '30%' }}>
                                {t.value}
                            </th>
                            <th style={{ paddingBottom: 15, minWidth: '20%' }}>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.cart.map((cart, index) => {
                                const ct = new CalculateCart(cart, { howToPayPizza: pizza });

                                return (
                                    <TableRow key={"" + cart.id + index}>
                                        <Paragraph as="td" className="pt-1 pb-1">
                                            {cart.nome} {cart.parent_name ? '- ' + cart.parent_name : ''}
                                            {
                                                cart.observation ?
                                                    <Paragraph
                                                        as="small"
                                                        style={{ fontWeight: 200, display: 'block', paddingTop: 3, paddingBottom: 3, borderTop: '1px solid rgba(0, 0, 0, .1)' }}
                                                    >
                                                        Obs. {cart.observation}
                                                    </Paragraph>
                                                    : null
                                            }
                                            {
                                                cart.itens.adicionais.length ?
                                                    <Paragraph
                                                        as="small"
                                                        style={{ fontWeight: 200, display: 'block', paddingTop: 3, paddingBottom: 3, borderTop: '1px solid rgba(0, 0, 0, .1)' }}
                                                    >
                                                        Adicionais {cart.itens.adicionais.map(ad => ad.nome).join(', ')}
                                                    </Paragraph>
                                                    : null
                                            }
                                            {
                                                cart.itens.acompanhamentos.length ?
                                                    <Paragraph
                                                        as="small"
                                                        style={{ fontWeight: 200, display: 'block', paddingTop: 3, paddingBottom: 3, borderTop: '1px solid rgba(0, 0, 0, .1)' }}
                                                    >
                                                        Acompanhamentos: {cart.itens.acompanhamentos.map(ad => ad.nome).join(', ')}
                                                    </Paragraph>
                                                    : null
                                            }
                                            {
                                                cart.itens.sabor?.length ?
                                                    <Paragraph
                                                        as="small"
                                                        style={{ fontWeight: 200, display: 'block', paddingTop: 3, paddingBottom: 3, borderTop: '1px solid rgba(0, 0, 0, .1)' }}
                                                    >
                                                        Sabores: {cart.itens.sabor?.map(ad => ad.nome_sabor).join(', ')}
                                                    </Paragraph>
                                                    : null
                                            }
                                        </Paragraph>
                                        <Paragraph as="td" className="pt-1 align-center pb-1">
                                            {cart.quantity}
                                        </Paragraph>
                                        <Paragraph as="td" className="pt-1 align-center pb-1">
                                            R${ct.getEach().toFixed(2)}
                                        </Paragraph>
                                        <Paragraph as="td" className="align-center pl-1">
                                            {
                                                !cart.kitchen ?
                                                    <Button onClick={() => removeItem(index)}>
                                                        <Icon color="#ff4444">
                                                            close
                                                        </Icon>
                                                    </Button>
                                                    : null
                                            }
                                        </Paragraph>
                                    </TableRow>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </ModalStyled>
            <ModalOuter active={props.open} onClick={() => props.close()} />
        </>
    )
}

export default CartList;