import React from 'react';
import { ListItem } from '../components/general/ListItem.component';
import MainHeader from '../components/MainHeader';
import { useInit } from '../hooks/init.hook.hook';
import { Container } from '../styled/body.styled';
import { Row } from '../styled/flex.styled';
import { ThemeProvider } from 'styled-components';
import { mainTheme, GlobalStyles } from '../styled/theme.styled';
import Cart from '../components/cart/CartComponent';
import FloatingButton from '../components/FloatingButton/FloatingButton';

export const HomePage: React.FunctionComponent<any> = () => {
   const dat = useInit();
   
   return (
      <ThemeProvider theme={mainTheme(dat)}>
         <GlobalStyles />
         <MainHeader />
         <Container>
            <Row as="ul" grid justifyContent="flex-start" className="pt-5">
               {
                  dat?.departamento?.map((dpt: any) => (
                     dpt.categorias.map((cat: any) => (
                        <ListItem
                           key={cat.id}
                           onClick={{ dptId: dpt.id, catId: cat.id }}
                           data={cat}
                        />
                     ))
                  ))
               }
            </Row>
         </Container>
         <Cart />
         <FloatingButton />
      </ThemeProvider>
   );
}