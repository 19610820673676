import { CATEGORY_DATA } from '../actions/actionTypes';

/**
 *  This reducer will hold all the company data such as
 *  name, id categories, etc. everything the company need
 */

const initialState = {
    categories: {}
};

export const categoriesData = (state = initialState, action: any) => {
    switch (action.type) {
        case CATEGORY_DATA:

            const dt = {
                ...action.payload, 
                request_time: new Date()
            };

            return {
                ...state,
                categories: {
                    ...state.categories,
                    [action.tipo]: dt ?? null
                }
            };
        default:
            return state;
    }
};