import React from 'react';
import { handle404 } from '../../helpers/general.helper';
import { HeaderCategory, HeaderCategoryImg } from '../../styled/header.styled';
import { Title } from '../../styled/text.styled';
// @ts-ignore
import camera from '../../img/camera.webp';
import { Icon } from '../../styled/general.styled';
import { Columns } from '../../styled/flex.styled';
import { Value } from '../general/value.container';

interface iHeaderItems {
   img: string | null;
   notShowImg?: boolean;
   name: string;
   size?: number;
   ml?: number | string;
   right?: any;
   space?: string;
   children?: any;
   value?: number;
   titleFlex?: number;
   childFlex?: number;
   styleImage?: any;
}

export const Headeritems: React.FunctionComponent<iHeaderItems> = (props) => (
   <HeaderCategory style={{ marginLeft: props.ml || 0 }} align={props.space || undefined}>

      {
         props.notShowImg === true ?
            null
            :
            <HeaderCategoryImg src={props.img || camera} alt={props.name} onError={handle404} style={props.styleImage || {}} />
      }

      <Columns className={`flex-${props.titleFlex ? props.titleFlex : '7'}`} notAlignCenter={true}>
         <Title size={props.size || .9} className={props.value ? 'no-pad' : ''}>
            {props.name}
         </Title>
         {
            props.value ?
               <Value value={{ valueNoDiscount: props.value, value: props.value, hasDiscount: false }} />
               : null
         }

      </Columns>

      {
         props.right && typeof props.right === 'function' ?
            <button type="button" onClick={props.right} className="is-invisible">
               <Icon color="#ff4444">
                  cancel
               </Icon>
            </button>
            : null
      }

      {
         props.right && typeof props.right !== 'function' ?
            <div className={`flex-${props.childFlex ? props.childFlex : 5}`}>
               {props.children}
            </div>
            : null
      }
   </HeaderCategory>
);