import { combineReducers } from 'redux';
import { setApiData } from './reducer/setApiData.reducer';
import { categoriesData } from './reducer/categoriesData.reducer';
import { cart } from './reducer/cart.reducer';
import { iCartInitial } from '../cart/cart-initial';
import { iSetApiData } from '../helpers/types/iSetApiData';

export default combineReducers({
    setApiData,
    categoriesData,
    cart,
});

export type reduxState = {
    setApiData: iSetApiData;
    categoriesData: any;
    cart: Array<iCartInitial>;
}
