import styled from 'styled-components';

export const OuterBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100vw !important;
    overflow: hidden;
`; 

export const Body = styled.main`
    width: 100%;
    @media screen and (min-width: 800px) {
        width: 800px !important;
    }
`;

export const Container = styled.section`
    width: 100%;
`;

