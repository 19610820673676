import { isOneOf } from "../helpers/general.helper";
import { iCartInitial } from './cart-initial';

export class CalculateCart {

   constructor(private _cart: iCartInitial | Array<iCartInitial>, private _options: any = {}) { }

   /**
    * Calculate the pizza value
    * 
    * @param toSum Array<any>
    * @param value number
    * @returns number
    */
   private __calcSabor = (toSum: Array<any>, value: number): number => {

      const pay = this._options?.howToPayPizza ?? 'dividecara';

      switch (pay) {
         case 'dividecara':
            if (toSum.length <= 2)
               value = value / toSum.length;
            else {
               const valores = toSum.map((item: any) => item.valor);
               value = Math.max(...valores);
            }
            break;
         case 'divide':
            value = value / toSum.length;
            break;
         case 'cara':
            const valores = toSum.map((item: any) => item.valor);
            value = Math.max(...valores);
            break;
         default:
            if (toSum.length <= 2)
               value = value / toSum.length;
            else {
               const valores = toSum.map((item: any) => item.valor);
               value = Math.max(...valores);
            }
            break;
      }
      return value;
   }

   /**
    * * The sum of the products in cart for the get value
    * 
    * @param toSum Array<any>
    * @param field string
    * @returns number
    */
   private __sumAll = (toSum: Array<any>, field: string): number => {
      let value = toSum.reduce((prev: any, cur: any) => {
         
         const qtd = cur.quantity && cur.quantity !== 0 ? cur.quantity : 1;

         let valor = isOneOf(cur.valor_produto, undefined, true) ? cur.valor || 0 : 0;

         if (cur.pc_desconto)
            valor -= valor * cur.pc_desconto / 100;

         return parseFloat(((prev || 0) + (valor * qtd)).toFixed(2));
      }, 0);

      if (field === 'sabor') {
         value = this.__calcSabor(toSum, value);
         
      }
      else if (field === 'promo_pizza') {
         const values = toSum.map((promo: any) => {
            const options = promo.options;
            let result = 0;

            if (options.cobrar_vl_pizza) {
               if (promo.itens && promo.itens.sabor) {
                  const itemVals = promo.itens.sabor.map((item: any) => item.valor || 0)
                     .reduce((prev: any, cur: any) => parseFloat(prev || 0) + cur);
                  result += this.__calcSabor(promo.itens.sabor, itemVals);
               }
            }
            if (options.cobrar_borda) {
               if (promo.itens && promo.itens.borda && promo.itens.borda[0]) {
                  result += promo.itens.borda[0].valor;
               }
            }

            if (promo.itens && promo.itens.adicionais && promo.itens.adicionais.length > 0) {

               const itemVal = promo.itens.adicionais
                  .map((item: any) => {
                     const qtd = item.quantity && item.quantity !== 0 ? item.quantity : 1;
                     const valor = item.valor ? parseFloat(item.valor) : 0;
                     return valor * qtd;
                  })
                  .reduce((prev: any, cur: any) => parseFloat(prev || 0) + cur);
               result += itemVal;
            }
            return result;
         });
         value = values.reduce((prev: any, cur: any) => parseFloat(prev || 0) + cur);
      }
      return value;
   }

   getEach(item: iCartInitial = null): number {
      try {
         let cart: iCartInitial = null;
         if (!Array.isArray(this._cart) && item === null) {
            cart = { ...this._cart };
         } else {
            cart = item;
         }

         const itemVal = cart.value;
         let val = 0;

         Object.keys(cart.itens).forEach((key) => {
            const sum = this.__sumAll(cart.itens[key] ? cart.itens[key] : [], key);
            val += sum;
         })
         
         return (val + itemVal) * (cart.quantity || 1);
      } catch (e) {
         return 0;
      }
   }

   getAll(): number {
      if (Array.isArray(this._cart)) {
         const val = this._cart.map((item) => this.getEach(item));
         return val.reduce((prev, cur) => (prev || 0) + cur, 0);
      }
      return 0;
   }
}