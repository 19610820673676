import React from 'react';
import { iCartInitial } from '../../../cart/cart-initial';
import { Columns, Row } from '../../../styled/flex.styled';
import { Paragraph } from '../../../styled/text.styled';
import { ValueFromTo } from '../general.component';
import LoopExtra from './Extras';

interface iLoopExtra {
   loop: any;
   cart: iCartInitial
   cartOperation: any;
}

export const Side: React.FunctionComponent<iLoopExtra> = (props) => {

   const itens = props.loop.acompanhamento_itens;

   return (
      <Columns as="ul" justifyContent="flex-start" notAlignCenter={true}>
         {
            itens && itens.length > 0 ?
               itens.map((item: any) => (
                  <Row as="li" key={item.tipo_acompanhamento_nome} className="flex-12">

                     <ValueFromTo
                        tipo_acompanhamento_nome={item.tipo_acompanhamento_nome}
                        qtde_escolha_obrigatoria={item.qtde_escolha_obrigatoria}
                        qtde_max_escolha={item.qtde_max_escolha}
                     />

                     <LoopExtra
                        cart={props.cart}
                        cartOperation={props.cartOperation}
                        loop={item}
                        loopable="itens"
                        className="pl-3 border-left"
                        type="side"
                     />
                  </Row>
               ))
               :
               <Paragraph>
                  nenhum
                </Paragraph>
         }
      </Columns>
   )
}