import React from 'react';
import { iCartInitial } from '../../cart/cart-initial';
import { useTranslate } from '../../hooks/translate.hook';
import { BorderRounded, Collapse, CollapseHeader, IconPrimary } from '../../styled/general.styled';
import { Paragraph, Title, TitleColor } from '../../styled/text.styled';

import { Headeritems } from '../header/headerItems.component';
import { ValueFromTo } from './general.component';
import { Side } from './productsType/side.component';
import Extras from './productsType/Extras';
import { isDescritiva, isPromoPizza3 } from '../../helpers/general.helper';
import { AddItemButton, InputText } from '../../styled/forms.styled';
import { Row } from '../../styled/flex.styled';
import { Value } from '../general/value.container';
import { CalculateCart } from '../../cart/calculate.cart';


interface iSwitcherExtras {
   data: any;
   cart: iCartInitial;
   cartOperation: any;
   steps?: any;
   active?: any;
}

export const ObsAndQuantity: React.FunctionComponent<iSwitcherExtras> = (props) => {
   const t = useTranslate()
   return (
      <>
         <CollapseHeader
            as="li"
            className="flex-12"
         >
            <Row className={"pt-4 pb-3"}>
               <div className="flex-7">
                  <TitleColor color={"mainColor"}>
                     Quantos <em>{props.data.nome}</em> deseja?
                  </TitleColor>
                  {
                     props.cart?.itens?.adicionais?.length ?
                        <Paragraph>
                           Adicionais: {props.cart.itens.adicionais.map((adic) => `${adic.nome} ${adic.valor ? `(R$ ${adic.valor.toFixed(2)})` : ''}`).join(', ')}
                        </Paragraph>
                        : null
                  }
                  {
                     props.cart?.itens?.acompanhamentos?.length ?
                        <Paragraph>
                           Acompanhamentos: {props.cart.itens.acompanhamentos.map((adic) => `${adic.nome} ${adic.valor ? `(R$ ${adic.valor.toFixed(2)})` : ''}`).join(', ')}
                        </Paragraph>
                        : null
                  }
               </div>
               <div className="flex-5">
                  <Row justifyContent="flex-end">
                     {
                        props.cart.quantity > 1 ?
                           <AddItemButton onClick={props.cartOperation.removeOne}>
                              <IconPrimary>
                                 remove
                              </IconPrimary>
                           </AddItemButton>
                           : null
                     }
                     <BorderRounded color="mainColor">
                        <TitleColor color="mainColor" size={1}>
                           {props.cart.quantity || 1}
                        </TitleColor>
                     </BorderRounded>


                     <AddItemButton onClick={props.cartOperation.addOne}>
                        <IconPrimary>
                           add
                        </IconPrimary>
                     </AddItemButton>
                  </Row>
                  <Value value={{
                     value: new CalculateCart(props.cart).getEach(),
                     hasDiscount: false,
                     valueNoDiscount: new CalculateCart(props.cart).getEach()
                  }}
                     className="align-right flex-12"
                  />
               </div>
            </Row>

            <TitleColor as={"label"} htmlFor={"obs"} color={"mainColor"}>
               {t.observation}?
            </TitleColor>
            <p>
               <InputText
                  id={"obs"}
                  style={{ fontSize: '1rem', border: '1px solid rgba(0, 0, 0, .5)', outline: 'none' }}
                  placeholder={t.observation + "..."}
                  value={props.cart.observation}
                  onChange={(input) => props.cartOperation.addObservation(input.target.value)}
               />
            </p>
         </CollapseHeader>
      </>
   )
}


export const SwitcherExtras: React.FunctionComponent<iSwitcherExtras> = (props) => {

   const t = useTranslate();

   React.useEffect(() => {
      if (
         !(
            props.data.conjuntos
            || isDescritiva(props.data)
            || isPromoPizza3(props.data)
         )
      ) {
         if (props.steps) {
            props.steps[1]({
               ...props.steps[0],
               has: 2,
               names: ["Quantidade", "Adicionar ao carrinho"],
               action: props.steps[0].action,
            })
         }
      }
   }, [props.data])

   React.useEffect(() => {

      const options = {
         name: props.data.nome,
         meta: "adicionais",
         min: props.data.qtde_obrigatoria,
         max: props.data.qtde_permitida,
      }
      props.cartOperation.option = options
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.data]);

   return (
      props.steps[0].page === 1 ?
         <>
            {
               // When it has itens
               (props.data.itens && Array.isArray(props.data.itens)) ?
                  <>
                     <CollapseHeader
                        as="li"
                        className="flex-12"
                     >
                        <Headeritems
                           name={props.data.nome}
                           notShowImg={true}
                           img={null}
                           right={props.data.qtde_obrigatoria || props.data.qtde_permitida}
                        >
                           <ValueFromTo
                              tipo_acompanhamento_nome={null}
                              qtde_escolha_obrigatoria={props.data.qtde_obrigatoria}
                              qtde_max_escolha={props.data.qtde_permitida}
                           />
                        </Headeritems>

                     </CollapseHeader>

                     <Collapse
                        as="li"
                        className="flex-12 pl-1"
                     >
                        <Extras
                           cart={props.cart}
                           cartOperation={props.cartOperation}
                           loop={props.data}
                           loopable="itens"
                           className="border-bottom"
                        />
                     </Collapse>
                  </>
                  : null
            }

            {/* Products with side dishes */}
            {
               (props.data.adicional_itens && Array.isArray(props.data.adicional_itens)) ?
                  <>
                     {/* Side */}
                     <CollapseHeader
                        as="li"
                        className="flex-12 pl-1"
                     >
                        <Title as="h6" className="no-pad pt-2" style={{ marginLeft: -10 }}>
                           {t.side_dishes}
                        </Title>
                     </CollapseHeader>

                     <Collapse
                        as="li"
                        className="flex-12 pl-1"
                     >
                        <Side
                           cart={props.cart}
                           cartOperation={props.cartOperation}
                           loop={props.data}
                        />
                     </Collapse>

                     {/* Extras */}
                     <CollapseHeader
                        as="li"
                        className="flex-12 pl-1"
                     >
                        <Title
                           as="h6"
                           className="no-pad pt-2 pb-1"
                           style={{ marginLeft: -10 }}
                        >
                           {t.extras}
                        </Title>
                     </CollapseHeader>

                     <Collapse as="li" className="flex-12 pl-1">
                        <Extras
                           cart={props.cart}
                           cartOperation={props.cartOperation}
                           loop={props.data}
                           loopable="adicional_itens"
                           className="border-bottom"
                        />
                     </Collapse>
                  </>
                  : null
            }
            {/* Bebidas */}
            {
               props.data.produtos ?
                  <Extras
                     loop={props.data}
                     loopable="produtos"
                     cart={props.cart}
                     cartOperation={props.cartOperation}
                  />
                  : null
            }
         </>
         :
         <ObsAndQuantity {...props} />
   )
}