import React from 'react'
import { iSuccessMessage } from '../../pages/category.page'
import { SuccessFailure } from '../../styled/header.styled'
import { Headeritems } from '../header/headerItems.component'

interface iCategoryHeader {
    data: any;
}

export const CategoryHeader: React.FunctionComponent<iCategoryHeader> = (props) => (
    <>
        <Headeritems
            name={props.data.nome}
            img={props.data.img}
            size={1.2}
        />

        <div style={{ margin: '-1rem 1.5rem 1.5rem 1.5rem' }}>
            <hr style={{ opacity: .5 }} />
        </div>
    </>
)

interface iCategorySuccessMessage {
   success: iSuccessMessage;
   setSuccess: CallableFunction;
}

export const CategorySuccessMessage: React.FunctionComponent<iCategorySuccessMessage> = (props) => {

   React.useEffect(() => {
      if (props.success.active) {
         setTimeout(() => {
            props.setSuccess({...props.success, active: false})
         }, 2500)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.success.active]);

   return (
      <SuccessFailure active={props.success.active} success={props.success.success}>
         <p>
            {props.success.message}
         </p>
      </SuccessFailure>
   )
}