import React from 'react';
import { useParams } from '@reach/router';
import { Container } from '../styled/body.styled';

import { CategoryHeader, CategorySuccessMessage } from '../components/category/categoryHeader.component';
import { SwitcherList } from '../components/category/switcherList.component';
import MainHeader from '../components/MainHeader';
import { GlobalStyles, mainTheme } from '../styled/theme.styled';
import { ThemeProvider } from 'styled-components';
import { useInit } from '../hooks/init.hook.hook';
import Cart  from '../components/cart/CartComponent';
import ProductsModal from '../components/category/ProductsModal';
import FloatingButton from '../components/FloatingButton/FloatingButton';

export interface iSuccessMessage {
   active: boolean;
   success: boolean;
   message: string | null;
}

export const CategoryPage: React.FunctionComponent<any> = () => {

   const dat = useInit();
   const params = useParams();
   const [data, setData] = React.useState<any>(null);
   const [active, setActive] = React.useState({});
   const [success, setSuccess] = React.useState<iSuccessMessage>({ active: false, success: true, message: null });

   React.useEffect(() => {

      if (dat && dat.departamento) {
         const dpt = dat.departamento;

         if (params.cat && params.dpt) {
            const thisCategory = dpt[params.dpt]
               .categorias.filter((el: any) => (
                  el.id === params.cat
               ))[0];

            setData(thisCategory);
         }
         else {
            setData(dpt[params.dpt].categorias[0]);
         }
      }
      // eslint-disable-next-line
   }, [dat, params]);   

   return (
      <ThemeProvider theme={mainTheme(dat)}>
         <GlobalStyles />
         <MainHeader />
         {
            data ?
               <Container>
                  <CategoryHeader data={data} />
                  <CategorySuccessMessage success={success} setSuccess={setSuccess} />

                  <SwitcherList data={data} active={setActive} setSuccess={setSuccess}>
                     <ProductsModal active={[active, setActive]} />
                  </SwitcherList>
               </Container>
               : null
         }
         <Cart />
         <FloatingButton />
      </ThemeProvider>
   );
}