import React from 'react'
import * as Redux from 'react-redux';
import { useTranslate } from '../../hooks/translate.hook';
import { reduxState } from '../../redux/reducers';
import { Button } from '../../styled/forms.styled';
import { AddToCartStyled, ModalOuter, ModalStyled } from '../../styled/modal.styled';
import { Headeritems } from '../header/headerItems.component';
import { IconPrimary } from '../../styled/general.styled';
import { CalculateCart } from '../../cart/calculate.cart';
import { CartResumeModal } from './CartResumeModal';
import { CategorySuccessMessage } from '../category/categoryHeader.component';
import { iSuccessMessage } from '../../pages/category.page';
// @ts-ignore
import cart from '../../img/cart.png';
import { app } from '../../app_env';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import takePhoto from '../../helpers/takePhoto';
import { countCart } from '../../helpers/general.helper';
import { iCartInitial } from '@cart/cart-initial';


const CartComponent = () => {
    const t = useTranslate();
    const dispatch = Redux.useDispatch();
    const [cartRedux, storeData] = Redux.useSelector((state: reduxState) => [state.cart, state.setApiData?.data]);
    const [showCartModal, setShowCartModal] = React.useState(false);
    const [openCartList, setOpenCartList] = React.useState(false);
    const [success, setSuccess] = React.useState<iSuccessMessage>({ active: false, success: false, message: '' });
    const [camera, setCamera] = React.useState([false, {}, {}, {}, {}]);

    const cc = new CalculateCart(cartRedux, { howToPayPizza: storeData.como_cobrar_pizza });

    const closeAll = () => {
        setOpenCartList(false);
        setShowCartModal(false);
    }

    return (
        <>
            <CategorySuccessMessage
                success={success}
                setSuccess={setSuccess}
            />
            <CartResumeModal
                cartRedux={cartRedux}
                showCartModal={showCartModal}
                closeAll={closeAll}
                setOpenCartList={setOpenCartList}
                openCartList={openCartList}
                calculateCart={cc}
                setSuccess={setSuccess}
                camera={camera}
                setCamera={setCamera}
            />
            <ModalOuter active={cartRedux.length > 0 && showCartModal} onClick={() => closeAll()} z={2} />

            <AddToCartStyled
                active={cartRedux.length > 0 && !showCartModal}
                style={{ padding: "0 1rem" }}
                z={3}
                onClick={() => setShowCartModal(true)}
            >
                <div style={{ marginTop: -7 }}>
                    <Headeritems
                        name={t.cart + ` (${countCart(cartRedux)})`}
                        styleImage={{ width: 30, height: 30 }}
                        img={cart}
                        right={true}
                        space="space-between"
                        titleFlex={7}
                        size={1}
                        childFlex={2}
                    >
                        <Button>
                            <IconPrimary>
                                launch
                            </IconPrimary>
                        </Button>
                    </Headeritems>
                </div>
            </AddToCartStyled>
            <div className={cartRedux.length > 0 && !showCartModal ? 'cart-after' : ''}></div>
            {
                app.type === "mesa" && camera[0] ?
                    <>
                        <ModalStyled small={true} active={true} style={{ top: 25, height: 75 }}>
                            <p>
                                aponte sua câmera para a comanda e tire uma foto
                            </p>
                        </ModalStyled>
                        <div style={{
                            position: 'fixed',
                            top: 125,
                            right: 0,
                            left: 0,
                            bottom: 75,
                            zIndex: 10,
                        }}>
                            <Camera
                                onTakePhoto={(dataUri) => { takePhoto(dataUri, camera, setSuccess, setCamera, dispatch) }}
                                isFullscreen={false}
                                idealFacingMode={FACING_MODES.ENVIRONMENT}
                                isImageMirror={false}
                            />
                        </div>
                    </>
                    : null
            }
        </>
    )
}

export default CartComponent;