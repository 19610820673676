import React from 'react'
import * as Redux from 'react-redux'
import { Row } from '../../styled/flex.styled'
import { ButtonPadding } from '../../styled/forms.styled'
import { AddToCartStyled } from '../../styled/modal.styled'
import { Value } from './value.container'

interface iModalNextStep {
    cart: any;
    active: any;
    steps: any;
    setActive: CallableFunction;
    cartOperation: any;
}

type wait = [Date, CallableFunction];


/**
 * * Returns the next step, e.g. comments, edge, etc.
 * 
 * @param props 
 * @returns 
 */
export const ModalNextStep: React.FunctionComponent<iModalNextStep> = (props) => {

    const dispatch = Redux.useDispatch();

    const [waitOneSec, setWaitOneSec]: wait = React.useState(null);

    const nextStep = () => {

        const now = new Date();

        const diference = waitOneSec !== null ? (now.getTime() - waitOneSec.getTime()) > 700 : true;

        // Verify if have at least one second since last click, to prevent double clicks
        if (waitOneSec === null || diference) {

            const steps = props.steps[0];
            // If page is less than the quantity of steps
            if (steps.page < steps.has) {
                // Go to next step
                props.steps[1]({
                    ...steps,
                    page: steps.page + 1
                });
            } else {
                // Add to cart
                props.cartOperation.addProductToCart(props.active, dispatch, props.setActive)
                props.steps[1]({
                    ...steps,
                    page: 1,
                })
            }

            setWaitOneSec(new Date());
        }
    }

    return (
        <AddToCartStyled active={Object.keys(props.active).length > 0}>
            <Row justifyContent="space-evenly">

                <Value value={{
                    value: props.cart.value
                }}
                    valueClass="font-3 bold"
                />

                <ButtonPadding color="mainColor" onClick={nextStep}>
                    {props.steps[0].names[props.steps[0].page - 1]}
                </ButtonPadding>

            </Row>
        </AddToCartStyled>
    )
}
