import React from 'react';
import { useGetCategory } from '../../hooks/getCategory.hook';
import ItemFromHere from '../lists/ItemFromHere';
import ItemFromURL from '../lists/ItemFromURL';

export interface iSwitcher {
   data: any;
   active: CallableFunction;
   children: any;
   setSuccess: CallableFunction;
}

export const SwitcherList: React.FunctionComponent<iSwitcher> = (props) => {

   useGetCategory(props.data);

   if (props.data.itens && Array.isArray(props.data.itens)) {
      return <ItemFromHere {...props} />;

   } else if (typeof props.data.itens === 'string') {
      return (
         <>
            <ItemFromURL
               {...props}
               id={props.data.id}
               type={props.data.tipo}
            />
            {props.children}
         </>
      )
   } else {
      return <div></div>
   }
}
