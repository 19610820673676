import { iCartInitial } from './cart-initial';
import { isDescritiva, isPromoPizza3 } from "../helpers/general.helper";
import { CartPizza } from "./pizza.cart"
import { Cart } from "./cart.cart";
import { CartPromoGeral } from "./promo-geral.cart";
import { DrinksCart } from './DrinksCart';


/**
 * * Returns and instace of Cart to use in Cart Operations
 * 
 * @param active item active
 * @param addToCart addToCart function (set state)
 * @param cart cart state
 * @param errors errors array
 * @param options option like edge
 * @param opt optional settings
 * @returns right instance of Cart
 */
export const cart = (
    active: any,
    addToCart: CallableFunction,
    cart: iCartInitial,
    errors: CallableFunction,
    options: any = {},
    opt: [any, CallableFunction],
) => {
    if (active.pizzas) {
        return new CartPizza(addToCart, cart, errors, options, opt);
    }
    else if (active.produtos) {
        return new DrinksCart(addToCart, cart, errors, options, opt);

    }
    else if (active.conjuntos || isDescritiva(active) || isPromoPizza3(active)) {
        return new CartPromoGeral(addToCart, cart, errors, options, opt);
    }
    else {
        return new Cart(addToCart, cart, errors, options, opt);
    }
}