import React from 'react';
import { Columns } from '../../../styled/flex.styled';
import { Title } from '../../../styled/text.styled';
import { PromoPizza } from './promo-pizza.component';
import Extras from './Extras';
import { ObsAndQuantity } from '../switcherExtras.component';
import { isDescritiva, isPromoPizza3 } from '../../../helpers/general.helper';


interface iPromoData {
   tp_conjunto: string;
   produto?: Array<any>;
   bebidas?: Array<any>;
   valor_produto?: boolean;
   cobrar_bebida?: boolean;
}

interface iPromo {
   data: {
      conjuntos: Array<iPromoData>;
      desconto?: number;
      descricao: string;
      id: number;
      img: string | null;
      nome: string;
      tipo: number;
      valor: number;
      valor_sem_desconto: number;
      valor_tem_desconto: boolean;
   };
   setError: CallableFunction;
   cartOperation: any;
   cartHolder: any;
   step: [any, CallableFunction];
   opt: any;
   setActive: any;
}

export const Promo: React.FunctionComponent<iPromo> = (props) => {

   const cn = { className: "border-left pl-3" }
   const data = props.data.conjuntos;
   const [eraseNonPizza, setEraseNonPizza] = React.useState(false);

   React.useEffect(() => {
      if (
         props.data.conjuntos
         &&
         !(
            isDescritiva(props.data)
            || isPromoPizza3(props.data)
         )
      ) {
         if (props.step) {
            props.step[1]({
               ...props.step[0],
               has: 2,
               names: ["Quantidade", "Adicionar ao carrinho"],
               action: props.step[0].action,
            })
         }
      }
   }, [props.data])

   return (
      <Columns as="ul" justifyContent="flex-start" notAlignCenter={true}>
         {
            props.step[0].page === 1 ?
               <>
                  <PromoPizza erase={setEraseNonPizza} {...props} opt={props.opt} />

                  {
                     props.data.conjuntos && props.data.tipo !== 1 && !eraseNonPizza ?
                        data.map((promo: any, index: number) => {
                           return (
                              <React.Fragment key={index}>
                                 {
                                    promo.tp_conjunto !== 'pizza' ?
                                       <li>
                                          <Title as="h6" className="no-pad pt-2 pb-2">
                                             {promo.tp_conjunto}
                                          </Title>
                                       </li>
                                       : null
                                 }
                                 <li className="flex-12">
                                    <Columns as="ul" justifyContent="flex-start" notAlignCenter={true} className="flex-12">
                                       {
                                          promo.produto.map((prod: any, id: number) => (
                                             promo.tp_conjunto !== 'pizza' ?
                                                <Extras
                                                   key={promo.tp_conjunto + id}
                                                   cart={props.cartHolder}
                                                   cartOperation={props.cartOperation}
                                                   loop={{
                                                      ...prod,
                                                      indexCat: index,
                                                      valor_produto: promo.valor_produto ?? promo.cobrar_bebida,
                                                      pc_desconto: props.data.desconto ?? 0,
                                                   }}
                                                   loopable={promo.tp_conjunto === 'produto' ? 'itens' : 'bebidas'}
                                                   type="promo_geral"
                                                   {...cn}
                                                />
                                                : null
                                          ))
                                       }


                                    </Columns>
                                 </li>
                              </React.Fragment>
                           )
                        })
                        :
                        !eraseNonPizza && props.data.tipo === 1 ?
                           <Extras
                              cart={props.cartHolder}
                              cartOperation={props.cartOperation}
                              loop={{ desc: [props.data] }}
                              loopable="desc"
                              type="promo_descritiva"
                              {...cn}
                           />
                           : null

                  }
               </>
               :
               <ObsAndQuantity
                  data={props.data}
                  cart={props.cartHolder}
                  cartOperation={props.cartOperation}
               />
         }
      </Columns>
   );
}
