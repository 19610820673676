import React from 'react';
import { app } from '../../app_env';
import { readMore } from '../../helpers/replacer';
import { Paragraph, Title } from '../../styled/text.styled';
import { iListItem } from './ListItem.component';
import { Value } from './value.container';


export const ListItemHeader: React.FunctionComponent<iListItem> = (props) => {
   const [more, setMore] = React.useState<boolean>(false);

   const desc = readMore(props?.data?.descricao ?? "", more);

   return (
      <div className="flex-12">
         <header>
            {
               props.data.valor ?
                  <Value
                     value={{
                        value: props.data.valor,
                        hasDiscount: props.data.valor_tem_desconto,
                        valueNoDiscount: props.data.valor_sem_desconto,
                     }}
                  />
                  : null
            }
            <Title
               align="center"
               style={{ marginTop: 15 }}
               size={props.data.size || .85}
               w={props.data.weight || 500}
               color={props.data.color || "rgba(0,0,0,.8)"}
            >
               {props.data.nome}
            </Title>
            {
               app.type !== "garcon" ?
                  <Paragraph align="center" className="resume">
                     {desc[0]}
                     {
                        desc[1] ?
                           <button
                              style={{
                                 border: 'none',
                                 color: 'blue',
                                 padding: '0 0 0 2px',
                                 margin: 0,
                                 background: 'none',
                              }}
                              onClick={() => setMore(true)}>
                              Ler mais
                           </button>
                           : null
                     }
                  </Paragraph>
                  : null
            }
         </header>
      </div>
   )
};