import React from 'react';
import loadash from 'lodash';
import { Columns, Row } from '../../../styled/flex.styled';
import { Paragraph, Title } from '../../../styled/text.styled';
import { Value } from '../../general/value.container';
import { CartButtons } from '../../general/buttons.component';
import { hasOneOf } from '../../../helpers/general.helper';
import { CHECK_TYPES } from '../../../constants/geral';

interface iLoopExtra {
    loop: any;
    className?: string;
    [key: string]: any;
    name?: string;
    cart: any;
    cartOperation: any;
    type?: string;
}

interface iMaxMin {
    max: number;
    name: string;
    id: number;
    min?: number;
    pizza?: boolean;
}

const Extras: React.FunctionComponent<iLoopExtra> = (props) => {

    const name = props.name || 'nome';

    const loop = props.loop[props.loopable];

    const [maximo, setMaximo] = React.useState({});

    const maxMin = (): iMaxMin => {

        let max: iMaxMin = {
            max: null,
            name: null,
            id: null,
            min: null,
            pizza: false
        };

        if (props.loop.qtde_max_escolha) {
            max = {
                max: props.loop.qtde_max_escolha,
                name: props.loop.tipo_acompanhamento_nome,
                id: props.loop.tipo_acompanhamento_id,
                min: props.loop.qtde_escolha_obrigatoria || 0,
            }
        } else if (props.loop.qtde_permitida) {
            max = {
                max: props.loop.qtde_permitida,
                name: props.loop.nome,
                id: props.loop?.id_monte || props.loop.id,
                min: props.loop.qtde_obrigatoria,
            }
            props.cartOperation.opton = max;
        } else if (props.loop.maxKind) {
            max = {
                max: props.loop.maxKind,
                name: 'sabor',
                id: props.loop.id,
                min: 1,
                pizza: true,
            }
        }
        return max;
    }

    React.useEffect(() => {
        const m = maxMin();
        if (!loadash.isEqual(m, maximo)) {
            setMaximo(m);
            props.cartOperation.option = m;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Columns
            as="ul"
            justifyContent="flex-start"
            notAlignCenter={true}
            className={props.className || ''}
        >
            {
                loop && loop.length > 0 ?
                    loop.map((item: any, index: number) => {

                        let valueShowed: number = 0;
                        let hasDiscount = false;

                        if (typeof props.loop.valor_produto === 'undefined' || props.loop.valor_produto === true) {

                            valueShowed = props.loop.pc_desconto ?
                                item.valor - (item.valor * (props.loop.pc_desconto / 100))
                                : item.valor

                        } else {
                            valueShowed = 0
                        }
                        if (props.type === 'sabores' && props.loop.cobrar_vl_pizza === false) {
                            valueShowed = 0;
                        }

                        if (props.types === 'sabores' && (item.valor_tem_desconto || props.loop.pc_desconto || props.loop.cobrar_vl_pizza === false)) {
                            hasDiscount = true;
                        }

                        return (
                            <Row as="li" key={item.id + item[name] + index} className="flex-12">

                                <Columns
                                    notAlignCenter={true}
                                    justifyContent="flex-start"
                                    className="pb-1 flex-7"
                                    onClick={
                                        hasOneOf(props.type, ...CHECK_TYPES) ?
                                            () => props.cartOperation.update({
                                                ...item,
                                                max: maximo,
                                                indexCat: props.loop.indexCat ?? null,
                                                valor_produto: props.loop.valor_produto,
                                                pc_desconto: props.loop.pc_desconto,
                                            },
                                                '+',
                                                props.type,
                                            )
                                            : () => { }
                                    }
                                >
                                    <Title
                                        as="h6"
                                        size={1}
                                        style={{ margin: 0 }}
                                    >
                                        {item[name]}
                                    </Title>

                                    <Paragraph>
                                        {item.descricao || 'Sem descricao'}
                                    </Paragraph>

                                </Columns>

                                <Columns
                                    notAlignCenter={true}
                                    justifyContent="flex-end"
                                    className="pb-1 flex-5"
                                >

                                    <CartButtons
                                        item={{
                                            ...item,
                                            indexCat: props.loop.indexCat ?? null,
                                            max: maximo,
                                            valor_produto: props.loop.valor_produto,
                                            pc_desconto: props.loop.pc_desconto,
                                        }}
                                        cartOperation={props.cartOperation}
                                        type={props.type || 'extra'}
                                    />

                                    <Value value={{
                                        value: valueShowed,
                                        hasDiscount: hasDiscount,
                                        valueNoDiscount: item.valor_sem_desconto
                                    }}
                                        className="align-right flex-12"
                                    />
                                </Columns>
                            </Row>
                        )
                    })
                    :
                    <Paragraph>
                        nenhum
                    </Paragraph>
            }
        </Columns>
    )
}

export default Extras;