import Axios from 'axios';
import { sendToTable } from '../fetchTable/sendToTable';
import { validate } from '../fetchTable/validate';


const takePhoto = async (
    photo: string,
    camera: any,
    success: CallableFunction,
    setCamera: CallableFunction,
    dispatch: any,
) => {
    const imageName = `${new Date().getTime().toString()}-${Math.random().toString()}`;
    const json = validate(camera[2], camera[1], camera[3].cart, camera[4], imageName);

    sendToTable(json, camera[3].closeModal, success, dispatch);

    Axios.post(
        "https://app.alphi.com.br/save-table-photo",
        {
            name: imageName,
            photo,
        }
    )
    .then((data) => console.log(data))
    .catch((err) => console.log(err))
    setCamera([false, {}, {}, {}, {}]);
}

export default takePhoto;