import React from 'react';
import { cartInitial } from '../../../cart/cart-initial';
import { useTranslate } from '../../../hooks/translate.hook';
import { Columns, Row } from '../../../styled/flex.styled';
import { IconPrimary } from '../../../styled/general.styled';
import { Title, TitleColor } from '../../../styled/text.styled';
import Extras from './Extras';

interface iPizzaSabores {
   id: number;
   img?: string;
   opcao?: number;
   nome: string;
   sabores?: Array<any>;
}
interface iPizzaBordas {
   descricao: string;
   id_borda: number;
   nome: string;
   opcao: number;
   valor: number;
   valor_sem_desconto: number;
   valor_tem_desconto: boolean;
}

interface iPizza {
   data: {
      adicionais?: Array<any>;
      bebidas?: Array<any>;
      bordas?: Array<iPizzaBordas>;
      descricao?: string;
      id: number;
      img?: string;
      nome: string;
      pizzas?: Array<iPizzaSabores>;
      sabores?: any;
      qtde_sabores: number;
      tamanho: string;
      tipo_massa?: any;
      parent?: any;
      opt?: {
         cobrar_borda?: boolean;
         cobrar_vl_pizza?: boolean;
         mostrar_borda?: boolean;
      }
   };
   setError?: CallableFunction;
   cartOperation?: any;
   cartHolder?: any;
   steps?: any;
   promoAction?: any;
   title?: string;
}

export const Pizza: React.FunctionComponent<iPizza> = (props) => {

   const t = useTranslate();

   React.useEffect(() => {

      const goTo = !props.data.opt
         || typeof props.data.opt.mostrar_borda === 'undefined'
         || props.data.opt.mostrar_borda === true ?
         t.goToBordas
         : t.goToExtras;

      props.steps[1]({
         ...props.steps[0],
         has: 2,
         names: [goTo, props.title || props.steps[0].names[0]],
         action: props.promoAction || props.steps[0].action,
      });

      if (props.promoAction) {
         props.cartOperation.addPizzaHeader(
            cartInitial({
               ...props.data,
               nome: props.data.tamanho,
            }, {
               cobrar_borda: props?.data?.opt?.cobrar_borda,
               cobrar_vl_pizza: props?.data?.opt?.cobrar_vl_pizza,
               mostrar_borda: props?.data?.opt?.mostrar_borda,
            })
         );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const goBackToKind = () => {
      props.steps[1]({
         ...props.steps[0],
         page: 1,
      })
   }

   const cn = { className: "border-left pl-3" }

   const title = { className: "no-pad pb-2 pt-2" }

   if (props.steps[0].page === 2) {
      return (
         <Columns as="ul" justifyContent="flex-start" notAlignCenter={true}>
            {
               props.promoAction ?
                  <Row as="li" onClick={() => props.promoAction()}>
                     <IconPrimary>
                        arrow_back
                            </IconPrimary>
                     <TitleColor color="mainColor">
                        {t.back} à promoção
                            </TitleColor>
                  </Row>
                  : null
            }
            <Row as="li" onClick={() => goBackToKind()}>
               <IconPrimary>
                  arrow_back
                            </IconPrimary>
               <TitleColor color="mainColor">
                  {t.back} para sabores
                    </TitleColor>
            </Row>


            {/* <li>
                <Title as="h6" {...title}>
                    {t.bebidas}
                    </Title>
                </li> */}

            {
               !props.data.opt || typeof props.data.opt.mostrar_borda === 'undefined' || props.data.opt.mostrar_borda === true ?
                  <>
                     <li>
                        <Title as="h6" {...title}>
                           {t.bordas}
                        </Title>
                     </li >
                     <Extras
                        cart={props.cartHolder}
                        cartOperation={props.cartOperation}
                        loop={props.data}
                        loopable="bordas"
                        type={props.data && props.data.pizzas ? "edge" : "promo_edge"}
                        {...cn}
                     />
                  </>
                  : null
            }

            <li>
               <Title as="h6" {...title}>
                  {t.extras}
               </Title>
            </li>
            <Extras
               cart={props.cartHolder}
               cartOperation={props.cartOperation}
               loop={{ ...props.data, maxKind: undefined }}
               loopable="adicionais"
               type={props.data && props.data.pizzas ? "extra" : "promo_extra"}
               {...cn}
            />
         </Columns >
      )
   }
   else {
      return (
         <Columns as="ul" justifyContent="flex-start" notAlignCenter={true}>
            {
               props.promoAction ?
                  <Row as="li" onClick={() => props.promoAction()}>
                     <IconPrimary>
                        arrow_back
                            </IconPrimary>
                     <TitleColor color="mainColor">
                        {t.back}
                     </TitleColor>
                  </Row>
                  : null
            }
            {/* <li>
                    <Title as="h6" {...title}>
                    {t.massas}
                    </Title>
                </li> */}

            <li>
               <Title as="h6" {...title}>
                  {t.sabores}
               </Title>
            </li>
            <li>
               {
                  props.data?.pizzas?.map((sabores) => (
                     <Columns
                        key={sabores.id}
                        as="ul"
                        justifyContent="flex-start"
                        notAlignCenter={true}
                     >
                        <li>
                           <Title as="h6" {...title}>
                              {sabores.nome}
                           </Title>
                        </li>
                        <Extras
                           cart={props.cartHolder}
                           cartOperation={props.cartOperation}
                           loop={{
                              ...sabores,
                              maxKind: props.data.qtde_sabores,
                              indexCat: props.data?.parent?.nome ?? null,
                           }}
                           loopable="sabores"
                           name="nome_sabor"
                           type="pizza"
                           {...cn}
                        />
                     </Columns>
                  ))
               }
               {
                  props.data.sabores ?
                     <Extras
                        cart={props.cartHolder}
                        cartOperation={props.cartOperation}
                        loop={{
                           ...props.data,
                           maxKind: props.data.qtde_sabores,
                           indexCat: props.data?.parent?.nome ?? null
                        }}
                        loopable="sabores"
                        name="nome_sabor"
                        type="sabores"
                        {...cn}
                     />
                     : null
               }
            </li>
         </Columns >
      );
   }
}
