import React from 'react';
import * as Redux from 'react-redux';
import { asyncSetCategoryData } from "../redux/actions/action-functions.actions";
import { reduxState } from '../redux/reducers';

export const useGetCategory = (data: any) => {

    const dispatch = Redux.useDispatch();

    const cat = Redux.useSelector((state: reduxState) => state.categoriesData)

    /**
     * Let's explain this stuff
     */
    React.useEffect(() => {
        // first, we use the history listen to detect when we need the function to work
        if (data) {
            const type = data.tipo;
            const itens = data.itens;

            const pizza = type === 'pizza' ?
                cat?.categories?.pizza?.categorias?.filter((pizza: any) => pizza.nome !== data.nome) ?? []
                : [];

            // when to request to the url
            if (
                typeof itens === 'string' // if we have itens
                && ( // and...
                    !cat.categories[type] // if we do not have any content with that type in categories
                    || ( // or...
                        cat.categories[type] // when we have the content type and we requested it more than 10 minutos ago
                        && new Date(cat.categories[type].request_time).getTime() < (new Date().getTime() - (1000 * 60 * 10))
                    )
                    || pizza.length > 0
                )
            ) {

                dispatch(
                    asyncSetCategoryData(itens, type)
                );
            }
        }
        // eslint-disable-next-line
    }, [data])
}