import * as type from './actionTypes';

export const asyncSetData = (value: any) => {
    return {
        type: type.ASYNC_CALL,
        payload: value,
        typeTo: type.COMPANY_DATA,
    }
};

export function asyncSetCategoryData(value: string, tp: string) {
    return {
        type: type.ASYNC_CALL,
        payload: value,
        typeTo: type.CATEGORY_DATA,
        tipo: tp,
    }
};

export function cartAction(data: any, tp: string) {
    return {
        type: tp,
        payload: data,
    }
}

export function clearCart() {
    return {
        type: type.CLEAR_CART,
        payload: {},
    }
}

export function addAllToCart(data: any[]) {
    return {
        type: type.ADD_ALL,
        payload: data,
    }
}

export function removeItemByIndex(index: number) {
    return {
        type: type.REMOVE_BY_INDEX,
        payload: index,
    }
}