import { Cart } from './cart.cart';
import { iCartInitial, cartInitial } from './cart-initial';
import { cartAction } from '../redux/actions/action-functions.actions';
import { ADD_TO_CART } from '../redux/actions/actionTypes';



export class DrinksCart extends Cart {

    private addToCart = (active: any, dispatch: any, setActive: CallableFunction, item: iCartInitial[]) => {
        dispatch(cartAction(item, ADD_TO_CART));
        this.reset(cartInitial(active));
        setActive({});
    }


    public addProductToCart = (active: any, dispatch: any, setActive: CallableFunction) => {

        const addToCart = (item: iCartInitial[]) => this.addToCart(active, dispatch, setActive, item);
        const cart = this._cart;

        const drinks: iCartInitial[] = cart
            .itens
            .adicionais
            .map((item) => cartInitial({ ...item, type: cart.type }, { defaultObservation: cart.observation }));

        addToCart(drinks)
    }
}