import { CalculateCart } from "../cart/calculate.cart";
import { iCartInitial } from "../cart/cart-initial";

export interface iProducts {
    nome: any;
    id: any;
    tipo: any;
    quantidade: any;
    adicionais: any;
    acompanhamentos: any;
    valor: any;
    observacao: any;
}

export const configureCart = (cart: Array<iCartInitial>, store: any = {}): Array<iProducts> => {

    const format: Array<iProducts> = [];

    cart.forEach((item) => {
        if (!item.kitchen) {
            
            const calculate = new CalculateCart(item)
            const valorTotal = calculate.getEach(item)

            const formatted = {
                nome: item.nome,
                id: item.id,
                tipo: item.type,
                quantidade: item.quantity,
                adicionais: item.itens?.adicionais ?? [],
                acompanhamentos: item.itens?.acompanhamentos ?? [],
                valor: item.value,
                observacao: item.observation ?? "",
                valor_total: valorTotal,
            }

            if (item.itens?.sabor?.length) {
                const sabores = item.itens.sabor.map((sabor) => ({
                    id: sabor.id_sabor || sabor.id,
                    nome: sabor.nome_sabor || sabor.nome || sabor.sabor,
                    valor: sabor.valor,
                    category: sabor.category || "",
                }))
                const borda = item.itens?.borda ? item.itens?.borda[0] : {}

                const calc = new CalculateCart(item, { howToPayPizza: store.como_cobrar_pizza })
                const val = calc.getEach()

                Object.assign(formatted, { sabor: sabores }, { borda }, { valor: val, valor_total: val })
            }

            if (item.itens?.promos?.length) {
                Object.assign(formatted, { itens: item.itens.promos })
            }

            format.push(formatted);
        }
    });

    return format;
}

interface iImportProducts {
    close_account: number;
    coupon: string | null;
    coupon_id: number;
    created_at: string | Date;
    customer: string;
    customer_id: number;
    delivery_amount: string;
    discount_amount: string;
    gift: any;
    id: number;
    id_mesa: any;
    impresso: any;
    mesa: string | number;
    order_num: string | number;
    paid: any;
    payment_id: any;
    payment_method: any;
    phone: string;
    products: string;
    products_amount: string;
    restaurant_id: 7434
    ret_iugu: null
    schedule: null
    status: string;
    total_amount: string;
    tp_pedido: string;
    type: string;
    updated_at: string | Date;
}

export const importCart = (products: Array<iImportProducts>): Array<iCartInitial> => {

    const cart: Array<iProducts> = [];

    products.forEach((productsJson: any) => {
        const obj = JSON.parse(productsJson.products);
        cart.push(...obj);
    });

    const format: Array<iCartInitial> = cart.map((item) => ({
        id: item.id,
        nome: item.nome,
        type: item.tipo,
        itens: {
            adicionais: item.adicionais,
            acompanhamentos: item.acompanhamentos,
            // sabor?: Array<any>;
            // promos?: Array<any>;
            // promo_pizza?: Array<any>;
            // [key: string]: Array<any>;
        },
        quantity: item.quantidade,
        value: item.valor,
        observation: item.observacao,
        descricao: "",
        parent_name: "",
        kitchen: true,
    }));

    return format;
}
