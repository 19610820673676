import React from 'react'
import { IconPrimary, ItemImage, ProductItem, CartCounting, AbsoluteButtonsAction, Icon } from '../../styled/general.styled';
import { ListItemHeader } from './ListItemHeader.component';
// @ts-ignore
import camera from '../../img/camera.webp';
import { handle404 } from '../../helpers/general.helper';
import { ADD_TO_CART, REMOVE_FROM_CART } from '../../redux/actions/actionTypes';
import { cartAction } from '../../redux/actions/action-functions.actions';
import { useDispatch } from 'react-redux';
import { cartInitial } from '../../cart/cart-initial';
import { ButtonAddOrRemoveSingle } from '../../styled/forms.styled';
import { useParams } from '@reach/router';
import { Link } from '@reach/router';

export interface iListItem {
   data: object | any;
   onClick?: any;
   actions?: boolean
   setSuccess?: CallableFunction;
   cart?: Array<any>
}

interface iWrapContainer extends iListItem {
   children: any;
   setItem: any;
   onMouseOver?: any;
}

const WrapContainer: React.FunctionComponent<iWrapContainer> = (props) => {
   const { id } = useParams();
   return (
      !props.onClick || typeof props.onClick === 'function' ?
         <ProductItem
            ref={props.setItem}
            onClick={!props.actions ? props.onClick : function () { }}
            onMouseOver={props.onMouseOver || function () { }}
         >
            {props.children}
         </ProductItem>
         :
         <ProductItem as={Link} to={`/${id}/${props.onClick.dptId}/${props.onClick.catId}`}>
            {props.children}
         </ProductItem>
   )
}

export const ListItem: React.FunctionComponent<iListItem> = (props) => {

   const itemRef = React.useRef(null);
   const [showButtons, setShowButtons] = React.useState(false);

   const dispatch = useDispatch();

   const addToCart = () => {
      if (props.data.data) {
         const format = cartInitial(props.data.data);
         dispatch(cartAction(format, ADD_TO_CART));
         props.setSuccess({
            active: true,
            success: true,
            message: `${props.data.data.nome} adicionado ao carrinho`,
         })
      }
   }

   const removeFromCart = () => {
      if (props.data.data) {
         const format = cartInitial(props.data.data);
         dispatch(cartAction(format, REMOVE_FROM_CART));
         props.setSuccess({
            active: true,
            success: 'warning',
            message: `${props.data.data.nome} retirado do carrinho`,
         })
      }
   }

   return (
      <>
         {
            props.actions ?
               <AbsoluteButtonsAction
                  showButtons={showButtons}
                  parentProperties={itemRef.current}
                  onMouseOver={() => setShowButtons(true)}
                  onMouseLeave={() => setShowButtons(false)}
               >
                  <ButtonAddOrRemoveSingle
                     as="button"
                     success={true}
                     onClick={addToCart}
                  >
                     <Icon>
                        add
                     </Icon>
                  </ButtonAddOrRemoveSingle>

                  <ButtonAddOrRemoveSingle
                     as="button"
                     success={false}
                     onClick={removeFromCart}
                  >
                     <Icon>
                        remove
                     </Icon>
                  </ButtonAddOrRemoveSingle>
               </AbsoluteButtonsAction>
               : null
         }
         <WrapContainer {...props} setItem={itemRef} onMouseOver={() => setShowButtons(true)}>
            {
               props?.cart?.length > 0 ?
                  <CartCounting>
                     <div>
                        <span>
                           {props.cart.reduce((prev, cur) => (prev || 0) + cur.quantity, 0)}
                        </span>
                        <IconPrimary>
                           shopping_cart
                        </IconPrimary>
                     </div>
                  </CartCounting>
                  : null
            }
            <ItemImage
               src={props.data.img || camera}
               onError={handle404}
               alt={props.data.nome}
            />

            <ListItemHeader {...props} />
         </WrapContainer>
      </>
   )
}