import { COMPANY_DATA } from '../actions/actionTypes';

/**
 *  This reducer will hold all the company data such as
 *  name, id categories, etc. everything the company need
 */

const initialState = {
  data: {
    payload: {}
  }
};

export const setApiData = (state = initialState, action: any) => {
    switch (action.type) {
      case COMPANY_DATA:
        return {
          ...state,
          data: action.payload ?? null
        };
      default:
        return state;
    }
};