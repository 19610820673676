import React from 'react';
import { useTranslate } from '../../hooks/translate.hook';
import { Columns, Row } from '../../styled/flex.styled';
import { ButtonPadding } from '../../styled/forms.styled';
import { Border, Icon } from '../../styled/general.styled';
import { Paragraph, RoundedText, TitleColor } from '../../styled/text.styled';
import { iCartResumeModal } from './CartResumeModal';
import { countCart } from '../../helpers/general.helper';


const ShowOptions: React.FunctionComponent<iCartResumeModal> = (props) => {
    const t = useTranslate();
    const handleOpenCartList = () => {
        props.setOpenCartList(!props.openCartList);
    }
    return (
        <Columns>
            <Row justifyContent="space-between">
                <Row className="flex-6">
                    <RoundedText bg={null} border={null} borderWidth={0}>
                        {countCart(props.cartRedux)}
                    </RoundedText>
                    <Paragraph style={{ fontSize: "1.1rem", paddingLeft: 5 }}>
                        {t.itens(props.cartRedux.length)}
                    </Paragraph>
                </Row>
                <Border>
                    <ButtonPadding color="light" onClick={handleOpenCartList}>
                        <TitleColor color="mainColor">
                            {t.see_list}
                        </TitleColor>
                    </ButtonPadding>
                </Border>
            </Row>
            <Row>
                <Row className="flex-6">
                    <RoundedText bg="mainColor" border="light" borderWidth={1}>
                        <Icon color="white" style={{ fontSize: "1.3rem" }}>
                            shopping_cart
                        </Icon>
                    </RoundedText>
                    <Paragraph style={{ paddingLeft: 5, fontSize: "1rem" }}>
                        Total:
                    </Paragraph>
                    <TitleColor color="light" style={{ paddingLeft: 5, fontSize: "1.1rem" }}>
                        R${props.calculateCart.getAll().toFixed(2)}
                    </TitleColor>
                </Row>
            </Row>
            {props.children}
        </Columns>
    )
}

export default ShowOptions