import { isDescritiva, isPromoPizza3 } from "../helpers/general.helper";

export interface iCartInitial {
    id: string;
    nome: string;
    descricao: string;
    type?: string;
    parent_name: any;
    itens: {
        adicionais: Array<any>;
        acompanhamentos: Array<any>;
        sabor?: Array<any>;
        promos?: Array<any>;
        promo_pizza?: Array<any>;
        [key: string]: Array<any>;
    };
    quantity: number;
    value: number;
    options?: {
        [key: string]: any;
    }
    mesa?: string | null;
    nome_cliente?: string | null;
    observation: string;
    kitchen: boolean;
}

const arr: Array<any> = [];

export const cartInitial = (data: any, opt: any = {}): iCartInitial => ({
    id: data.id ?? null,
    nome: data.nome ?? null,
    descricao: data.descricao ?? null,
    parent_name: data?.parent?.nome ?? null,
    itens: { adicionais: arr, acompanhamentos: arr },
    quantity: data.quantity || 0,
    value: data.valor ?? 0,
    options: opt,
    type: data.conjuntos || isDescritiva(data) || isPromoPizza3(data) ? 'promo' : data.type,
    mesa: null,
    nome_cliente: null,
    observation: opt.defaultObservation || "",
    kitchen: false,
});