import styled from 'styled-components';

interface iTitle {
  align?: string;
  size?: number;
  w?: number;
  color?: string;
}

export const Title = styled.h4<iTitle>`
  text-align: ${(props) => props.align || 'left'};
  font-size: ${(props) => props.size || 1}rem;
  font-weight: ${(props) => props.w || 500};
  color: ${(props) => props.color || '#111'};
  transition: ease-in-out .5s;
  text-transform: capitalize;
`;

interface iTitleColor {
  color: string;
}

export const TitleColor = styled(Title)<iTitleColor>`
  color: ${(props) => props.theme[props.color]};
  margin: 0;
  padding: 0;
`;

interface iParagraph {
  align?: string;
}
export const Paragraph = styled.p<iParagraph>`
  font-size: .8rem;
  text-align: ${(props) => props.align || 'left'};
`;

interface iValueStyled {
  discount: boolean;
  lineThrough?: boolean;
  bold: boolean;
}

export const ValueStyled = styled.p<iValueStyled>`
    font-weight: ${(props) => props.bold ? 'bold' : 'normal'};
    margin: 0 0 -10px 0;
    padding: 0;
    text-align: center;
    color: ${(props) => !props.discount ? props.theme.success : props.theme.danger};
    text-decoration: ${(props) => props.lineThrough ? 'line-through' : 'none'};
`;

interface iRoundText {
  bg: string | null;
  border: string | null;
  text?: string;
  borderWidth: number | null;
}

export const RoundedText = styled.p.attrs({
  className: 'flex _center'
}) <iRoundText>`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 1.1rem;
  background-color: ${(props) => props.theme[props?.bg ?? 'light']};
  color: ${(props) => props.theme[props?.text ?? 'mainColor']};
  border: ${(props) => props.border ? props.borderWidth + 'px solid ' + props.theme[props.border || 'mainColor'] : 'none'};
`;