import React from 'react';
import * as Redux from 'react-redux';
import { reduxState } from '../../redux/reducers';
import { Row } from '../../styled/flex.styled';
import { ListItem } from '../general/ListItem.component';
import { iSwitcher } from '../category/switcherList.component';


interface iOpenModal {
   cat: any
   pp: any
   cart: any
   allExtras: any
   type: string
}

const OpenModal: React.FunctionComponent<iOpenModal> = (props) => {
   const { cat, pp, cart, allExtras } = props;

   return (
      <ListItem
         key={cat.id}
         data={{ ...cat, weight: 700, size: .9, color: '#000', type: props.type }}
         onClick={() => pp.active({ ...cat, adicionais: allExtras, type: props.type })}
         actions={false}
         cart={cart.filter((item: any) => item.nome === cat.nome && item.id === cat.id)}
      />
   )
}

interface iItens {
   item: any
   extras: any[]
   data: any
   cart: any[]
   superProps: any
}

const Itens: React.FC<iItens> = (props) => {
   let localExtras: Array<any> = [];

   if (props.item.adicional_itens) {
      localExtras.push(...props.item.adicional_itens);
   }
   if (props.item.itens) {
      localExtras.push(...props.item.itens);
   }
   if (props.item.adicionais) {
      props.extras.push(...props.item.adicionais);
   }

   if (props.item.categorias && Array.isArray(props.item.categorias)) {
      return (
         props.item.categorias.map((cat: any, index: number) => {
            let myExtras = []
            if (cat.adicionais) {
               myExtras.push(...cat.adicionais);
            }
            const allExtras = [...props.extras, ...localExtras, ...myExtras]

            return <OpenModal key={index} cat={cat} pp={props.superProps} cart={props.cart} allExtras={allExtras} type={props.data.tipo} />
         })
      )
   }
   const allExtras = [...props.extras, ...localExtras]

   return <OpenModal cart={props.cart} cat={props.item} allExtras={allExtras} pp={props.superProps} type={props.data.tipo} />
}

const ItemFromHere: React.FunctionComponent<iSwitcher> = (props) => {

   const cart = Redux.useSelector((state: reduxState) => state.cart)

   let extras: Array<any> = [];

   if (props.data?.adicionais) {
      extras.push(...props.data.adicionais)
   }

   return (
      <Row as="ul" justifyContent="flex-start">
         {
            props.data?.itens?.map((item: any) => {
               return (
                  <>
                     {
                       item?.categorias?.length > 1 &&
                        <h3 className="flex-12 category-class">
                           {item.nome}
                        </h3>
                     }
                     <Itens
                        item={item}
                        extras={extras}
                        data={props.data}
                        cart={cart}
                        superProps={props}
                     />
                  </>
               )
            })
         }
         {props.children}
      </Row>
   );
}

export default ItemFromHere;