import React from 'react';
import { Link } from "@reach/router"
import { useParams } from '@reach/router';
import { sizeName } from '../../helpers/general.helper';
import { RowCategories } from '../../styled/flex.styled';
import { IconPrimary } from '../../styled/general.styled';

interface iCategoriesList {
   departamento?: Array<any>;
   location?: any;
}

const CategoriesList: React.FunctionComponent<iCategoriesList> = (props) => {

   const params = useParams();

   const isRouteHere = (dpt: number | string, id: number | string) => (
      (params.dpt && params.cat && params.dpt === (dpt + "") && params.cat === (id + "")) ?
         true
         : false
   );

   return (
      <RowCategories as="ul">
         <li className="flex-1"></li>
         <li className={`has-text-centered`} style={{
            position: 'absolute',
            backgroundColor: 'white',
            // width: 50,
            paddingLeft: 50,
            paddingRight: 10,
            marginLeft: -50,
            marginTop: 5,
            borderRadius: '20%',
         }}>
            <Link to={`/${params.id}`}>
               <IconPrimary>
                  apps
               </IconPrimary>
            </Link>
         </li>
         {
            props?.departamento?.map((dpt: any) => (
               dpt.categorias.map((cat: any) => (
                  <li key={cat.id} className={`flex-${sizeName(cat.nome)} flex-md-2 has-text-centered`}>
                     <Link
                        to={`/${params.id}/${dpt.id}/${cat.id}`}
                        className={`link ${isRouteHere(dpt.id, cat.id) ? 'active' : ''}`}
                     >
                        <small className="font-2">
                           {cat.nome}
                        </small>
                        <i />
                     </Link>
                  </li>
               ))
            ))
         }
      </RowCategories>
   );
}

export default CategoriesList;