import { iCartInitial } from "./cart-initial";
import { Cart } from "./cart.cart";

export class CartPromoGeral extends Cart {

    /**
     * Will update the promo pizza stuff, almost the same in CartPizza
     * 
     * @param newItem any
     * @param operation string
     * @param type string
     */
    updatePromoPizza = (newItem: any, operation: string, type: string = 'promo_pizza') => {


        let result: Array<any> = [];
        let field = "";

        const kinds = this._cart.itens?.promo_pizza ?? [];

        let itemIndex: number;
        let promoItem: any;

        if (kinds.length > 0) {
            itemIndex = this.__promoPizzaIndex(newItem);
            promoItem = kinds[itemIndex];
        }

        switch (type) {
            case 'pizza':
                result = this._updatePizza(newItem, itemIndex);
                field = "sabor";
                break;
            case 'sabores':
                result = this._updatePizza(newItem, itemIndex);
                field = "sabor";
                break;
            case 'promo_edge':
                result = this._updateEdge(newItem, operation);
                field = "borda";
                break;
            case 'dough':
                result = this._updateDough(newItem, operation);
                field = "massa";
                break;
            case 'promo_extra':
                result = this._updateExtra(newItem, operation, promoItem.itens && promoItem.itens.adicionais ? [...promoItem.itens.adicionais] : []);
                field = "adicionais";
                break;
            case 'side':
                result = this._updateSide(newItem, operation);
                field = "acompanhamentos";
                break;
            default:
                break;
        }

        try {

            let promo_p = this._cart.itens?.promo_pizza ?? [];

            if (itemIndex !== -1) {

                promoItem.itens[field] = result;

                promo_p[itemIndex] = promoItem;
            }

            const newCart: iCartInitial = {
                ...this._cart,
                itens: {
                    ...this._cart.itens,
                    promo_pizza: promo_p,
                }
            }
            this._cart = newCart;
            this._addToCart(newCart);
        } catch ($err) {
            console.log($err);

        }
    }

    updatePromoGeral = (newItem: any, operation: string, type: string = 'promo_geral') => {

        const has = this._hasPromo(newItem);
        const alreadyExists = this._alreadyExists(newItem, type);
        const promos = this._cart.itens.promos ? [...this._cart.itens.promos] : [];

        let result: Array<any> = [];

        if (type !== 'promo_descritiva') {

            if (has !== -1) {
                promos.splice(has, 1, newItem);
                result = promos;
            } else {
                result = [...promos, newItem];
            }
        } else {
            result = [...promos];
            if (alreadyExists.length === 0) {
                result.push({ ...newItem, quantity: 1 });
            } else {
                result = this._performOperation(result, newItem, operation, has, alreadyExists[0]);
            }
        }

        const newCart: iCartInitial = {
            ...this._cart,
            itens: {
                ...this._cart.itens,
                promos: result,
            }
        }
        this._cart = newCart;
        this._addToCart(newCart);
    }

    updatePromoType3 = (item: any, operation: string = '+') => {

        let result: Array<any> = [];

        const alreadyExists = this._alreadyExists(item, 'promo_pizza_3');

        if (alreadyExists.length === 0) {
            result.push({ ...item, quantity: 1 });
        } else {
            result = this._performOperation(result, item, operation, alreadyExists.length, alreadyExists[0]);
        }

        const newCart: iCartInitial = {
            ...this._cart,
            itens: {
                ...this._cart.itens,
                promo_pizza: result,
            }
        }
        this._cart = newCart;
        this._addToCart(newCart);
    }

    /**
     * Will check if is a promo geral or promo pizza and return to the right method
     * 
     * @param newItem any
     * @param operation string
     * @param type string
     */
    update = (newItem: any, operation: string, type: string = 'promo_geral') => {

        // if is promo geral or descritiva, gost to updatePromo Geral
        if (type === 'promo_geral' || type === 'promo_descritiva') {
            this.updatePromoGeral(newItem, operation, type);
        }
        else if (type === 'promo_pizza_3') {
            this.updatePromoType3(newItem, operation)
        }
        // otherwise, to update promo pizza
        else {
            this.updatePromoPizza(newItem, operation, type);
        }
    }

    addPizzaHeader = (initial: iCartInitial) => {

        const kinds = this._cart.itens?.promo_pizza ?? [];

        const alE = kinds.filter((pizzas: any) => pizzas.parent_name === initial.parent_name);

        if (alE.length > 0) {
            const index = kinds.findIndex((pizza: any) => pizza.parent_name === initial.parent_name);

            kinds.splice(index, 1, initial);
        } else {
            kinds.push(initial);
        }

        const newCart: iCartInitial = {
            ...this._cart,
            itens: {
                ...this._cart.itens,
                promo_pizza: kinds,
            }
        }

        this._cart = newCart;
        this._addToCart(newCart);
    }

    _hasPromo = (newItem: any): number => {
        return this.cart.itens?.promos?.findIndex((item: any) => item.indexCat === newItem.indexCat) ?? -1;
    }


    /**
     * Will update the pizza stuff
     * 
     * @param newItem any
     * @param placeToAdd number
     * @returns Array<any>
     */
    _updatePizza = (newItem: any, placeToAdd: number): Array<any> => {

        // Exists any promo pizza?
        const promo = typeof placeToAdd === 'number' && placeToAdd !== -1 ? this._cart.itens.promo_pizza[placeToAdd] : {};

        // This promo pizza, get the sabor or a empty array
        let kinds = Object.keys(promo).length > 0 ? promo.itens?.sabor ?? [] : [];

        // Checking how much kind exsists
        const exists = this.howMuchExists(newItem, 'sabores', true);

        // Checking if it is bigger than the max
        if (kinds.length < newItem.max.max && exists === 0)
            kinds.push(newItem); // add
        // if already exists the type
        else if (exists === 1) {
            // We check where is it
            const index = kinds.findIndex((item: any) => (
                item.id === newItem.id && item.nome_sabor === newItem.nome_sabor
            ));
            // and take it out
            kinds.splice(index, 1)
        }
        // If the max is one, or not defined
        else if (newItem.max.max === 1 || !newItem.max.max) {
            kinds = [newItem]; // we add only it
        }
        // send an error
        else {
            this._errors({ errors: [`LIMIT_OF_${ newItem.max.max }`] });
        }

        return kinds;
    }

    _updateEdge = (newItem: any, operation: string): Array<any> => {
        return [newItem];
    }

    _updateDough = (newItem: any, operation: string): Array<any> => {
        return [];
    }

    get getPizza() {

        if (this._cart.itens.promo_pizza)
            return this._cart.itens.promo_pizza[0];
        else
            return [];
    }

    /**
     * Find the index of the promo cat
     * 
     * @param item any
     * @returns number
     */
    private __promoPizzaIndex = (item: any): number => {

        // The pizza promos
        const pp = this._cart.itens.promo_pizza ? [...this._cart.itens.promo_pizza] : [];

        // Finding it index
        const id = pp.findIndex((el: any) => el.parent_name === item.indexCat)

        return id;
    }

    existsPromo = (item: any) => {
        // The pizza promos
        const pp = this._cart.itens.promo_pizza ? [...this._cart.itens.promo_pizza] : [];

        // Finding it index
        const id = pp.filter((el: any) => el.parent_name === item.indexCat && el.nome === item.tamanho);

        return id.length > 0;
    }
}