import React, { Dispatch, SetStateAction } from 'react';
import { CalculateCart } from '../../cart/calculate.cart';
import { iCartInitial } from '../../cart/cart-initial';
import { CartBottom } from '../../styled/modal.styled';
import CartList from './CartList';
import SendComponent from './SendComponent';
import ShowOptions from './ShowOptions';
import { danger } from '../../styled/theme.styled';
import { Button } from '../../styled/forms.styled';
import { Row } from '../../styled/flex.styled';
import { RoundedText, TitleColor } from '../../styled/text.styled';
import { Icon } from '../../styled/general.styled';
import { useTranslate } from '../../hooks/translate.hook';
import PaymentComponent from './PaymentComponent';

export interface iCartResumeModal {
    cartRedux: Array<iCartInitial>;
    showCartModal: boolean;
    closeAll: () => void;
    setOpenCartList: Dispatch<SetStateAction<boolean>>;
    openCartList: boolean;
    calculateCart: CalculateCart;
    setSuccess: CallableFunction;
    camera: any;
    setCamera: CallableFunction;
}

export const CartResumeModal: React.FunctionComponent<iCartResumeModal> = (props) => {
    const t = useTranslate();
    const [finish, setFinish] = React.useState<boolean>(false);

    return (
        <CartBottom active={props.cartRedux.length > 0 && props.showCartModal}>
            <Row justifyContent="space-between">
                <TitleColor color="light" size={1.3}>
                    {t.cart}
                </TitleColor>
                <Button onClick={props.closeAll}>
                    <RoundedText as="div" bg="mainColor" border='danger' borderWidth={2}>
                        <Icon style={{ color: danger, fontWeight: 'bold' }}>
                            close
                        </Icon>
                    </RoundedText>
                </Button>
            </Row>
            {
                !finish ?
                    <>
                        <ShowOptions {...props}>
                            <SendComponent
                                cart={props.cartRedux}
                                closeModal={props.closeAll}
                                setSuccess={props.setSuccess}
                                setFinish={setFinish}
                                camera={props.camera}
                                setCamera={props.setCamera}
                            />
                        </ShowOptions>
                        <CartList
                            cart={props.cartRedux}
                            open={props.openCartList}
                            close={() => props.setOpenCartList(false)}
                        />
                    </>
                    :
                    <PaymentComponent
                        closeModal={props.closeAll}
                        setSuccess={props.setSuccess}
                    />
            }
        </CartBottom>
    )
}
