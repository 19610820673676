import styled from 'styled-components';


interface iModalStyled {
    active: boolean;
    small?: boolean;
    z?: number | undefined;
}

const Fixed = styled.div<iModalStyled>`
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    display: ${(props) => props.active ? 'block' : 'none'};
    visibility: ${(props) => props.active ? 'visible' : 'hidden'};
    border-radius: 10px;
`;

export const ModalStyled = styled(Fixed)`
    top: ${(props) => !props.small ? 5 : 23}%;
    bottom: ${(props) => !props.small ? 5 : 23}%;
    left: 5%;
    right: 5%;
    background-color: ${(props) => props.theme.light};
    z-index: 5;
    overflow: scroll;
    padding: 1rem 1.5rem;
    padding-bottom: 10vw;
`;

export const ModalOuter = styled(Fixed)`
    position: fixed;
    background-color: ${(props) => props.theme.transparentDark};
    z-index: ${(props) => props.z || '4'};
`;

interface iAddToCartStyled {
    top?: number;
}

export const AddToCartStyled = styled(ModalStyled) <iAddToCartStyled>`
    bottom: 0;
    top: ${(props) => props.top || 90}%;
    left: 0;
    right: 0;
    @media screen and (min-width: 850px) {
        max-width: 60% !important;
        left: 50%;
        margin-left: -30%;
    }
    overflow: hidden; 
    z-index: ${(props) => props.z || 6};
    border-radius: 5px;
    border-top: 3px solid ${(props) => props.theme.mainColor};
    padding-bottom: .5rem;
`;

export const CartBottom = styled(AddToCartStyled)`
    top: 40%;
    background-color: ${(props) => props.theme.mainColor};
    color: white;
    font-weight: 500;
    font-size: 1.1rem;
    z-index: 3;
    overflow-y: auto;
`;