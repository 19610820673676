import { normalize } from '../helpers/replacer';
import React from 'react';
import { useParams } from '@reach/router';
// @ts-ignore
import camera from '../img/camera.webp';
import { Container } from '../styled/body.styled';
import { Row } from '../styled/flex.styled';
import { useInit } from '../hooks/init.hook.hook';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles, mainTheme } from '../styled/theme.styled';
import MainHeader from '../components/MainHeader';
import Cart  from '../components/cart/CartComponent';
import ItemFromHere from '../components/lists/ItemFromHere';
import ProductsModal from '../components/category/ProductsModal';

export const SearchPage: React.FunctionComponent<any> = () => {

   const data = useInit();
   const [items, setItems] = React.useState<Array<any>>();
   const params = useParams();
   const [active, setActive] = React.useState({});


   React.useEffect(() => {

      let results: Array<any> = [];

      data?.departamento?.forEach((dpt) => {
         dpt?.categorias?.forEach((cat) => {
            if (cat.itens && Array.isArray(cat.itens)) {

               cat?.itens?.forEach((item) => {
                  let use: boolean = false;

                  Object.keys(item)?.forEach((key) => {
                     if (item && item[key] && typeof item[key] === 'string') {
                        const exists = normalize(item[key], true).includes(params.search);
                        if (exists) {
                           use = true;
                        }
                     }
                  });
                  if (use) {
                     results.push(item);
                  }
               });
            }
         });
      });
      setItems(results);
   }, [params.search]);

   return (
      <ThemeProvider theme={mainTheme(data)}>
         <GlobalStyles />
         <MainHeader />
         <Container>
            <Row as="ul" justifyContent="flex-start" className="pt-5">
               <ItemFromHere
                  data={{ itens: items }}
                  active={setActive}
                  setSuccess={function () { }}
               >
                  <ProductsModal active={[active, setActive]} />
               </ItemFromHere>
            </Row>
         </Container>
         <Cart />
      </ThemeProvider>
   );
}