import * as Redux from 'react-redux'; 

export const useCurrency = () => {

    const { data } = Redux.useSelector((state: any) => state.setApiData);

    if (data.moeda) {
        return data.moeda
    } 
    return 'R$';
}