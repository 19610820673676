import React from 'react';
import * as Redux from 'react-redux';
import { isDescritiva, isPromoPizza3 } from '../../helpers/general.helper';
import { reduxState } from '../../redux/reducers';
import { Columns } from '../../styled/flex.styled';
import { ModalStyled, ModalOuter } from '../../styled/modal.styled';
import { Title } from '../../styled/text.styled';
import { Headeritems } from '../header/headerItems.component';
import { Pizza } from './productsType/pizza.component';
import { Promo } from './productsType/promo.component';
import { ObsAndQuantity, SwitcherExtras } from './switcherExtras.component';
import { cart } from '../../cart';
import { ModalNextStep } from '../general/addToCart.component';
import { useTranslate } from '../../hooks/translate.hook';
import { Errors } from '../general/errors.component';
import { cartInitial } from '../../cart/cart-initial';
import Extras from './productsType/Extras';

interface iModal {
    active?: [any, CallableFunction];
}

const ProductsModal: React.FunctionComponent<iModal> = (props) => {

    const dispatch = Redux.useDispatch();

    const [active, setActive] = props.active;

    const t = useTranslate();

    const [cartHolder, setCartHolder]: any = React.useState({});
    const opt: any = React.useState([]);
    const [error, setError]: any = React.useState({ errors: [] });

    const config: any = React.useRef(null);

    React.useEffect(() => {
        setCartHolder(cartInitial(active));
    }, [active]);

    Redux.useSelector((state: reduxState) => {

        const data = state.setApiData?.data;

        config.current = {
            secondEdge: data?.permitir_borda_2 ?? false,
            forceEdge: data?.obrigar_borda ?? false,
            howToPayPizza: data?.como_cobrar_pizza ?? 'dividecara',
        }
    })

    const cartOperation = cart(active, setCartHolder, cartHolder, setError, config.current, opt);

    const step = React.useState({
        has: 1,
        page: 1,
        names: [t.add_carrinho],
        action: function (active: any) {
            cartOperation.addProductToCart(active, dispatch, setActive)
        }
    });

    const close = () => {
        setActive({});
        opt[1]([]);
        step[1]({
            has: 1,
            page: 1,
            names: [t.add_carrinho],
            action: function (active: any) {
                cartOperation.addProductToCart(active, dispatch, setActive)
            }
        });
    };
// console.log(active);

    return (
        <>
            <ModalStyled
                small={
                    !(active.conjuntos || isDescritiva(active) || isPromoPizza3(active))
                    && !active.pizzas
                    && !active.itens
                    && !(active.itens && Array.isArray(active.itens) && active.itens.length > 0)
                    && !((active.adicional_itens && Array.isArray(active.adicional_itens) && active.adicional_itens.length)
                        || (active.produtos))

                }
                active={Object.keys(active).length > 0}
            >

                <Headeritems
                    name={active.nome}
                    img={active.img}
                    right={() => close()}
                    space="space-between"
                    value={active.valor}
                />
                {

                }
                <Columns as="ul" justifyContent="flex-start" notAlignCenter={true}>
                    {/* Adicionais */}
                    {
                        active.itens && Array.isArray(active.itens) && active.itens.length > 0 ?
                            <>
                                {
                                    active.itens ?
                                        <>
                                            {
                                                active.itens.map((item: any) => (
                                                    <SwitcherExtras
                                                        key={item.id}
                                                        data={item}
                                                        cart={cartHolder}
                                                        cartOperation={cartOperation}
                                                        steps={step}
                                                    />
                                                ))
                                            }
                                            <li className="flex-12 pl-1">
                                                <Title className="no-pad pt-2 pb-1" as="h6">
                                                    {t.extras}
                                                </Title>
                                                <Extras
                                                    loop={active}
                                                    loopable="adicionais"
                                                    cart={cartHolder}
                                                    cartOperation={cartOperation}
                                                />
                                            </li>
                                        </>
                                        :
                                        <ObsAndQuantity
                                            data={active}
                                            cart={cartHolder}
                                            cartOperation={cartOperation}
                                            steps={step}
                                        />
                                }
                            </>
                            : null
                    }
                    {
                        (active.adicional_itens && Array.isArray(active.adicional_itens) && active.adicional_itens.length)
                            || (active.produtos) ?
                            <SwitcherExtras
                                data={active}
                                cart={cartHolder}
                                cartOperation={cartOperation}
                                steps={step}
                            />
                            :
                            !active.conjuntos ?
                                <ObsAndQuantity
                                    data={active}
                                    cart={cartHolder}
                                    cartOperation={cartOperation}
                                    steps={step}
                                />
                                : null
                    }

                    {/* Promoções */}
                    {
                        active.conjuntos || isDescritiva(active) || isPromoPizza3(active) ?
                            <Promo
                                key={active.id}
                                data={active}
                                setError={setError}
                                cartOperation={cartOperation}
                                cartHolder={cartHolder}
                                step={step}
                                opt={config}
                                setActive={setActive}
                            />
                            : null
                    }
                    {/* Pizzas */}
                    {
                        active.pizzas ?
                            <li className="flex-12 pl-1">
                                <Pizza
                                    data={active}
                                    setError={setError}
                                    cartHolder={{ ...cartHolder }}
                                    cartOperation={cartOperation}
                                    steps={step}
                                />
                            </li>
                            : null
                    }
                </Columns>

            </ModalStyled>

            <Errors errors={error} setErrors={setError} />

            <ModalNextStep
                steps={step}
                cart={cartOperation}
                setActive={setActive}
                active={active}
                cartOperation={cartOperation}
            />

            <ModalOuter active={Object.keys(active).length > 0} onClick={() => close()} />
        </>
    );
}

export default ProductsModal;