import React from 'react'
import { useTranslate } from '../../hooks/translate.hook'
import { Row, Columns } from '../../styled/flex.styled';
import { Button, ButtonPadding } from '../../styled/forms.styled';
import { Icon } from '../../styled/general.styled';
import { ModalOuter, ModalStyled } from '../../styled/modal.styled';
import { TitleColor } from '../../styled/text.styled';
import { danger } from '../../styled/theme.styled';

interface iErrors {
    errors: { errors: Array<any> };
    setErrors: CallableFunction;
}

export const Errors: React.FunctionComponent<iErrors> = (props) => {

    const t = useTranslate();
    const active = props.errors?.errors?.length > 0 ?? false;

    const close = () => props.setErrors({ errors: [] });

    return (
        <>
            <ModalStyled small={true} active={active} style={{ zIndex: 6 }}>
                <Row justifyContent="flex-end">
                    <Button onClick={close}>
                        <Icon color={danger}>
                            close
                    </Icon>
                    </Button>
                </Row>
                <Columns justifyContent="center" notAlignCenter={false} style={{ flexWrap: "wrap" }}>
                    <Row as="ul" justifyContent="space-evenly" className="pb-5 flex-12">

                        {
                            props.errors?.errors?.map((err) => (
                                <TitleColor as="li" key={err} color="mainColor" className="border-bottom pb-1 pt-1">{t.error(err)}</TitleColor>) ?? null
                            )
                        }
                    </Row>
                    <ButtonPadding onClick={close}>
                        {t.close}
                    </ButtonPadding>
                </Columns>
            </ModalStyled>
            <ModalOuter active={active} onClick={close} style={{ zIndex: 5 }} />
        </>
    )
}
