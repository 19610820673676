import { call, put } from 'redux-saga/effects';

import axios from 'axios';

function* asyncCall(action: any): any {
    try {
        const res = yield call(
            axios.get, 
            action.payload
        );

        if ( res.data && res.data.departamento ) {
            res.data.departamento.forEach((element: any) => {
                element.categorias.sort( ( ant: any, at: any ) => ant.opcao && at.opcao ? ant.opcao - at.opcao : ant ); 
            });
        }

        yield put({
            type: action.typeTo,
            payload: res.data,
            tipo: action.tipo || null,
        });

    } catch(err) {
        yield put({
            type: action.typeTo,
            payload: { data: err } 
        });  
    }
}

export default asyncCall;