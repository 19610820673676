import { iCartInitial, cartInitial } from "./cart-initial";
import { cartAction } from "../redux/actions/action-functions.actions";
import * as rTypes from '../redux/actions/actionTypes';
import { CalculateCart } from "./calculate.cart";

export class CartParent {

    /**
     * Assign value to _addToCart and _cart
     * @param _addToCart CallableFuntion
     * @param _cart iCartInitial
     * @param _errors CallableFunction
     * @param _options any
     */
    constructor(
        protected _addToCart: CallableFunction,
        protected _cart: iCartInitial,
        protected _errors: CallableFunction,
        protected _options: any = {},
        private _prodOpt: [any, CallableFunction]
    ) { }

    /**
     * Verify if product exists in the cart
     * @param check any
     * @param type string
     * @returns Array<any>
     */
    protected _alreadyExists = (check: any, type: string): Array<any> => {

        let tp = '';

        if (type.includes('extra'))
            tp = 'adicionais';
        else if (type.includes('side'))
            tp = 'acompanhamentos'
        else if (type === 'pizza' || type === 'sabores')
            tp = 'sabor'
        else if (type === 'edge' || type === 'promo_edge')
            tp = 'borda'
        else if (type === 'promo_geral' || type === 'promo_descritiva')
            tp = 'promos'
        else if (type === 'promo_extra' || type === 'promo_pizza_3')
            tp = "promo_pizza"
        else
            tp = null;

        if (tp !== null) {

            const cartItens: any = this._cart.itens;

            let extras = cartItens && cartItens[tp] ? [...cartItens[tp]] : [];

            if (type === 'sabores' || type === 'promo_edge' || type === 'promo_extra' || type === 'promo_pizza_3')
                extras = cartItens && cartItens.promo_pizza ? cartItens.promo_pizza : [];

            if (type === 'extra') {

                return extras
                    .filter((item: any) => {
                        return (
                            (
                                (item.id_adicional !== undefined && item.id_adicional === check.id_adicional)
                                || (item.id === check.id)
                            )
                            && item.nome === check.nome
                        )
                    })
            }
            else if (type === 'side' || type === 'promo_descritiva')
                return extras.filter((item: any) => item.id === check.id && item.nome === check.nome);
            else if (type === 'pizza')
                return extras.filter((item: any) => item.id_sabor === check.id_sabor && item.nome_sabor === check.nome_sabor);
            else if (type === 'edge')
                return extras.filter((item: any) => item.id_borda === check.id_borda && item.nome_sabor === check.nome_sabor);
            else if (type === 'promo_geral')
                return extras.filter((item: any) => item.id === check.id && item.indexCat === check.indexCat);
            else if (type === 'sabores') {
                const [itens] = extras.filter((promo: any) => promo.parent_name === check.indexCat);
                return itens && itens.itens && itens.itens[tp] ?
                    itens.itens[tp].filter((item: any) => item.id_sabor === check.id_sabor && item.nome === check.nome)
                    : [];
            }
            else if (type === 'promo_edge') {
                const [itens] = extras.filter((promo: any) => promo.parent_name === check.indexCat);
                return itens && itens.itens && itens.itens[tp] ?
                    itens.itens[tp].filter((item: any) => item.id_borda === check.id_borda && item.nome === check.nome)
                    : [];
            }
            else if (type === 'promo_extra') {

                const [itens] = extras.filter((promo: any) => promo.parent_name === check.indexCat);

                return itens && itens.itens && itens.itens[tp] ?
                    itens.itens[tp].filter((item: any) => item.id_adicional === check.id_adicional && item.nome === check.nome)
                    : [];
            }
            else if (type === 'promo_pizza_3') {
                const itens = extras.filter((promo: any) => promo.id === check.id && promo.nome === check.nome);
                return itens;
            }
            else {
                return extras.filter((item: any) => item.max.id === check.max.id && item.max.name === check.max.name);
            }
        }

        return [];
    }

    /**
     * Perfoms the add/remove operations
     * 
     * @param varToReturn any
     * @param item any
     * @param operation string
     * @param conditional number
     * @param existItem any
     * @returns any
     */
    protected _performOperation(
        varToReturn: any,
        item: any,
        operation: string,
        conditional: number,
        existItem: any,
    ) {
        const index = existItem ? varToReturn.findIndex((item: any) => (
            item.id_adicional === existItem.id_adicional && item.nome === existItem.nome
        )) : null;

        switch (operation) {
            case '+':
                if ((!item.max.max || conditional < item.max.max) && index !== null) {
                    existItem.quantity += 1;
                    varToReturn.splice(index, 1, existItem);
                } else {
                    this._errors({ errors: [`LIMIT_OF_${item.max.max}`] });
                }
                break;
            case '-':
                if (existItem.quantity - 1 > 0 && index !== null) {
                    existItem.quantity -= 1;
                    varToReturn.splice(index, 1, existItem);
                }
                else {
                    varToReturn.splice(index, 1)
                }
                break;
            default:
                break;
        }
        return varToReturn;
    }

    /**
     * Update the adicionais (extras), you can add and remove items
     * @param newItem any
     * @param operation string
     * @returns Array<any>
     */
    protected _updateExtra(newItem: any, operation: string, extraParent: Array<any> = null): Array<any> {

        let extras: Array<any> = [];

        if (extraParent == null) {
            extras = [...this._cart.itens.adicionais];
        }
        else {
            extras = [...extraParent];
        }

        const exists = this._alreadyExists(newItem, extraParent ? 'promo_extra' : 'extra');

        const exists_in_topic = newItem.max.max ? this._alreadyExists(newItem, 'extra_topic') : null;

        const howManyTopic = exists_in_topic?.reduce((prev: any, cur: any) => prev + cur.quantity, 0) ?? null;

        if (exists.length === 0 && (!howManyTopic || howManyTopic < newItem.max.max)) {

            extras.push({ ...newItem, quantity: 1 });

            return extras;
        }

        const existItem = exists[0];

        return this._performOperation(extras, newItem, operation, howManyTopic, existItem);
    }

    /**
     * Update the acompanhamentos (side dishes) 
     * 
     * @param newItem any
     * @param operation string
     * @returns Array<any>
     */
    protected _updateSide = (newItem: any, operation: string): Array<any> => {

        const side = [...this._cart.itens.acompanhamentos];

        const exists = this._alreadyExists(newItem, 'side');
        const exists_in_topic = this._alreadyExists(newItem, 'side_topic');

        const howManyTopic = exists_in_topic.reduce((prev: any, cur: any) => prev + cur.quantity, 0);

        if (exists.length === 0 && howManyTopic < newItem.max.max) {

            side.push({ ...newItem, quantity: 1 });

            return side;
        } else if (howManyTopic === newItem.max.max && operation === '+') {
            this._errors({ errors: [`LIMIT_OF_${newItem.max.max}`] });
        }

        const existItem = exists[0];

        return this._performOperation(side, newItem, operation, howManyTopic, existItem);
    }

    addObservation = (obs: string) => {
        this._addToCart({ ...this._cart, observation: obs })
    }

    get cart(): iCartInitial {
        return this._cart
    };

    get value(): number {

        const val = new CalculateCart(this._cart, this._options);

        return val.getEach();
    }


    set option(fields: any) {

        let productOptions = [...this._prodOpt[0]];

        const alreadyExists = productOptions.filter((item: any) => item.name === fields.name);

        if (alreadyExists.length === 0)
            productOptions.push(fields);

        if (fields.name !== null) {
            this._prodOpt[0] = [...productOptions];
            this._prodOpt[1]([...productOptions]);
        }
    }

    get options() {
        return this._prodOpt[0];
    }

    set qtdItemsCart(newQtd: number) {
        this._addToCart({ ...this._cart, quantity: newQtd })
    }

    /**
     * Reset the cart data
     * @param cart iCartInitial
     */
    public reset = (cart: iCartInitial) => {
        this._cart = cart;
        this._addToCart(cart);
        this._prodOpt[1]([]);
    }


    private performAddToCart = (active: any, dispatch: any, setActive: CallableFunction) => {
        dispatch(cartAction(this._cart, rTypes.ADD_TO_CART));
        this.reset(cartInitial(active));
        setActive({});
    }

    public addProductToCart = (active: any, dispatch: any, setActive: CallableFunction) => {

        const addToCart = () => this.performAddToCart(active, dispatch, setActive);

        const options = this.options;
        const cart = this._cart;

        if (options.length > 0) {

            const errors: Array<any> = [];

            options.forEach((opt: any) => {

                let adic = cart.itens.adicionais?.filter((item) => item.max && item.max.name && item.max.name === opt.name);
                let edge = [];
                let { forceEdge = false } = this._options;

                if (adic.length === 0 && !opt.pizza) {
                    adic = cart.itens.acompanhamentos.filter((item) => item.max && item.max.name && item.max.name === opt.name);
                }
                else if (adic.length === 0 && opt.name === 'sabor') {
                    adic = cart.itens.sabor || [];
                    edge = cart.itens.borda || [];
                }

                if (adic.length > 0) {
                    const qtd = opt.name !== 'sabor' ? adic.reduce((prev, cur) => parseInt(prev || 0) + cur.quantity, 0) : adic.length;

                    const passGeneral = (qtd >= opt.min && (!opt.max || qtd <= opt.max));

                    if (opt.name === 'sabor' && forceEdge && edge.length > 0 && passGeneral) {
                        // pass
                    } else if (opt.name === 'sabor' && !forceEdge && passGeneral) {
                        // pass
                    } else if (opt.name === 'sabor' && forceEdge && edge.length === 0) {
                        errors.push('Você precisa adicionar uma borda')
                    }
                    else if (passGeneral) {
                        // pass
                    }
                    else if (opt.max && qtd > opt.max) {
                        errors.push(`Mais que o permitido de ${opt.name}, o máximo é ${opt.max}`)
                    } else if (opt.min !== undefined && opt.min !== 0) {
                        errors.push(`Quantidade insuficiente de ${opt.name}, o minimo é ${opt.min || 1}`)
                    }
                } else if (opt.min !== undefined && opt.min !== 0) {
                    errors.push(`Quantidade insuficiente de ${opt.name}, o minimo é ${opt.min || 1}`)
                }
            });
            if (errors.length === 0)
                addToCart();

            else {
                this._errors({ errors });
            }

        } else if (cart.type === 'promo') {
            if (!cart.itens.promos) {
                this._errors({ errors: ['Tem itens faltando'] })
            } else {
                addToCart();
            }
        } else {
            addToCart();
        }
    }

}