import * as Redux from 'react-redux';
import { portuguese } from '../languages/pt';
import { reduxState } from '../redux/reducers';

export const useTranslate = () => {

    const { data } = Redux.useSelector((state: reduxState) => state.setApiData);

    switch (data.lang) {
        case 'pt':
            return portuguese;
        default:
            return portuguese;
    }
}